import React, { useState, useEffect, useContext } from 'react';
import './CompDashCardGallery.css';
import Button from '@mui/material/Button';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { getUserPersonasByOrgId } from '../fxAPI/fxUserPersonasAPI.js';
import { getIntegrationsByOrgId } from '../fxAPI/fxIntegrationsAPI.js';
import CompDashCard from './CompDashCard.jsx';
import ChildTwo from './ChildTwo.jsx';
import { PanelContext } from '../Contexts/PanelContext';
import CompCRUDModal from './CompCRUDModal';
import CompAvatarChooserModal from './CompAvatarChooserModal.jsx';

const CompDashCardGallery = (props) => {
  const [error, setError] = useState(null);
  const [projectsByOrgId, setProjectsByOrgData] = useState([]);
  const [showMoreProjects, setShowMoreProjects] = useState(false);
  const [showMorePersonas, setShowMorePersonas] = useState(false);
  const [showMoreIntegrations, setShowMoreIntegrations] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);

  const openModal = (itemType, currentCRUDMethod, currentModalComponentData) => {
    setItemType(itemType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    if(itemType === "project") {
      setActionModalTitle('Create Playbook');
    } else if(itemType === "user-persona") {
      setActionModalTitle('Create Playbook');
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Fetch projects by org ID
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
  };

  /* USER PERSONAS */
  const [userPersonasByOrgId, setUserPersonasByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUserPersonasByOrgId = async (orgId) => {
    await getUserPersonasByOrgId(orgId, setError, setUserPersonasByOrgData);
    console.log('all user personas by org Id');
    console.dir(userPersonasByOrgId);
  }; 

  /* INTEGRATIONS */
  const [integrationsByOrgId, setIntegrationsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchIntegrationsByOrgId = async (orgId) => {
    await getIntegrationsByOrgId(orgId, setError, setIntegrationsByOrgData);
    console.log('all integrations by org Id');
    console.dir(integrationsByOrgId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchProjectsByOrgId(currentOrgId);
    fxAPIFetchUserPersonasByOrgId(currentOrgId);
    fxAPIFetchIntegrationsByOrgId(currentOrgId);
  }, []);

  // Handle "Show More" button click
  const handleShowMoreProjects = () => {
    setShowMoreProjects(!showMoreProjects);
  };

  const handleShowMorePersonas = () => {
    setShowMorePersonas(!showMorePersonas);
  };

  const handleShowMoreIntegrations = () => {
    setShowMoreIntegrations(!showMorePersonas);
  };

  // Define how many items to show initially (4 including the static one)
  const itemsToShowProjects = showMoreProjects ? projectsByOrgId.length : 3;
  const itemsToShowPersonas = showMorePersonas ? userPersonasByOrgId.length : 3;
  const itemsToShowIntegrations = showMoreIntegrations ? integrationsByOrgId.length : 3;

  /*
  const switchContext = (contextType, objectId, object) => {
    console.log('testing context switch for: ' + contextType + ' of ID: ' + objectId);
    props.updatePanelContext(contextType, objectId, object);
  };
  */

  /* Use Global Context to refresh Subjective Panel */
  const { triggerElementRefresh } = useContext(PanelContext);

  const handleRefreshElement = () => {
    triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
  };

  const handleSwitchPanelContext = (object_id, type, object) => {
    //updatePanelContext("objective", objective_id, object);
    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    handleRefreshElement();
  };

  /* OLD CONTEXT FUNCTION DO NOT USE */
  const { updatePanelContext } = useContext(PanelContext);

  const handleSwitchContext = (objectType, objectId, object) => {
    updatePanelContext(objectType, objectId, object);
  };

  return (
    <>
    <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={actionModalTitle} itemType={itemType} crudMethod={crudMethod} modalComponentData={modalComponentData}></CompCRUDModal>

    <div className="fx-comp-dash-pb-gallery-container">
      {/* Static "Create" item */}
      <div className="fx-comp-dash-pb-gallery-item fx-create-item">
        {props.galleryType === "playbook" &&
          <CompDashCard cardTitle="Create New Playbook">
            <div className="fx-create-item-plus" onClick={() => openModal("project", "create", [])}>+</div>
          </CompDashCard>
        }

        {props.galleryType === "persona" &&
          <CompDashCard cardTitle="Create New User Persona">
            <div className="fx-create-item-plus" onClick={() => openModal("user-persona", "create", [])}>+</div>
          </CompDashCard>
        }

        {props.galleryType === "integration" &&
          <CompDashCard cardTitle="Create New Integration">
            <div className="fx-create-item-plus" onClick={() => openModal("integration", "create", [])}>+</div>
          </CompDashCard>
        }
      </div>

      {/* Dynamically loaded items */}
      {props.galleryType === "playbook" &&
        <>
        {projectsByOrgId.slice(0, itemsToShowProjects).map((projectItem) => (
          <CompDashCard cardTitle={projectItem.name} key={projectItem.id}>
            <Button onClick={() => handleSwitchPanelContext(projectItem.project_id, "project", projectItem)}>
                  Playbook Detail
            </Button>
            <a href={`/dashboard/projects/${projectItem.project_id}`}>
              <Button>
                    Open
              </Button>
            </a>
            <Button onClick={() => openModal("project", "view-edit", projectItem)}>Edit</Button>
            <Button onClick={() => openModal("project", "delete", projectItem)}>Delete</Button>
          </CompDashCard>
        ))}
        </>
      }

      {/* Dynamically loaded items */}
      {props.galleryType === "persona" &&
        <>
        {userPersonasByOrgId.slice(0, itemsToShowPersonas).map((personaItem) => (
          <CompDashCard cardTitle={personaItem.name} key={personaItem.user_persona_id}>
            <Button onClick={() => handleSwitchPanelContext(personaItem.user_persona_id, "persona", personaItem)}>
                  Open Persona Detail
            </Button>
            <Button onClick={() => openModal("user-persona", "view-edit", personaItem)}>Edit</Button>
            <Button onClick={() => openModal("user-persona", "delete", personaItem)}>Delete</Button>
            <CompAvatarChooserModal userPersonaId={personaItem.user_persona_id} avatarId={personaItem.related_avatar} />
          </CompDashCard>
        ))}
        </>
      }

      {/* Dynamically loaded items */}
      {props.galleryType === "integration" &&
        <>
        {integrationsByOrgId.slice(0, itemsToShowIntegrations).map((integrationItem) => (
          <CompDashCard cardTitle={integrationItem.product} key={integrationItem.integration_id}>
            <Button onClick={() => handleSwitchPanelContext(integrationItem.integration_id, "integration", integrationItem)}>
                  Open Integration
            </Button>
            <Button onClick={() => openModal("integration", "view-edit", integrationItem)}>Edit</Button>
            <Button onClick={() => openModal("integration", "delete", integrationItem)}>Delete</Button>
          </CompDashCard>
        ))}
        </>
      }
    </div>

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "playbook" &&
      <>
      {projectsByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMoreProjects}>{showMoreProjects ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "persona" &&
      <>
      {userPersonasByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMorePersonas}>{showMorePersonas ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "integration" &&
      <>
      {integrationsByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMoreIntegrations}>{showMoreIntegrations ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    </>
  );
};

export default CompDashCardGallery;