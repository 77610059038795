import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './CompCRUDFormObjective.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createObjective, updateObjective } from '../fxAPI/fxObjectivesAPI.js';
import { getAnchorsByProjectId } from '../fxAPI/fxAnchorsAPI.js';
import { createSubjective } from '../fxAPI/fxSubjectivesAPI.js';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import subjectives from '../Data/ObjectiveTemplates/accessibility-basic.json';
import objectiveTemplates from '../Data/ObjectiveTemplates/template-options.json'; // Import the template options
import accessibilityTasks from '../Data/ObjectiveTemplates/accessibility-basic.json'; // Import tasks
import uiReviewTasks from '../Data/ObjectiveTemplates/ui-review.json'; // Import tasks

const CompCRUDFormObjective = (props) => {

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  

  const [useTemplate, setUseTemplate] = useState(false); // Toggle state
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
    //triggerTasks(event.target.value); // Trigger task creation
  };

  // Function to trigger tasks based on the selected template
  const triggerTasks = (templateKey, newObjectiveId) => {
    console.log('selected template: ' + selectedTemplate);
    console.log('template key: ' + templateKey);
    let tasks = [];

    switch (templateKey) {
      case 'accessibility-basic':
        tasks = accessibilityTasks.subjectives;
        break;
      case 'ui-review':
        tasks = uiReviewTasks.subjectives;
        break;
      default:
        tasks = [];
    }

    tasks.forEach((task) => createTask(task, newObjectiveId)); // Call createTask function for each task
  };

  // Mock function to simulate task creation
  const createTask = (task, newObjectiveId) => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    console.log(`Creating task: ${task.name}`);
    console.log(`Description: ${task.description}`);
    console.log(`Tags: ${task.tags.join(', ')}`);
    const relatedProject = params.compId;

    createSubjective(task.name, task.description, currentOrgId, newObjectiveId, relatedProject);
  };

  function getObjectiveViewName() {
    return props.currentComponentData?.objective_view_name || props.currentComponentData?.objective_name || props.currentComponentData?.name || 'Default Value';
  }

  const currentObjectiveName = getObjectiveViewName();

  function getObjectiveViewDescription() {
    return props.currentComponentData?.objective_view_description || props.currentComponentData?.objective_name || props.currentComponentData?.description || 'Default Value';
  }

  const currentObjectiveDescription = getObjectiveViewDescription();

  const [error, setError] = useState(null);

  const params = useParams();

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [updateObjectiveName, setUpdateObjectiveName] = useState(currentObjectiveName);
  const [updateObjectiveDescription, setUpdateObjectiveDescription] = useState(currentObjectiveDescription);

  /* ANCHORS */
  const [assignedAnchor, setAssignedAnchor] = useState(props.relatedAnchorId);
  const [anchorsByProject, setAnchorsByProjectData] = useState([]);
  const fxAPIFetchAnchorsByProjectId = async (projectId) => {
    await getAnchorsByProjectId(projectId, setError, setAnchorsByProjectData);
  };

  useEffect(() => {
    fxAPIFetchAnchorsByProjectId(params.compId);
  }, []);


  console.log('current objective data on form objective: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
    if(currentViewMode === "edit") {
      console.log('triggered edit objective');
    }
  };

  const handleChangeAssignedAnchor = (e) => {
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    setAssignedAnchor(e.target.value);
    console.log('set assigned anchor to: ' + e.target.value + ' related org: ' + relatedOrg);
    
  }

  const handleUpdateObjectiveNameChange = (e) => {
    setUpdateObjectiveName(e.target.value);
  }

  const handleUpdateObjectiveDescriptionChange = (e) => {
    setUpdateObjectiveDescription(e.target.value);
  }

  const checkSelectedTemplate = (e) => {
    e.preventDefault();
    console.log('selected template: ' + selectedTemplate);
  }

  const handleCreateObjective = async (event) => {
    event.preventDefault();
    if(params.type === "projects") {
      var relatedProject = params.compId;
    } else {
      var relatedProject = '';
    }
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const relatedAnchor = assignedAnchor;
    const progress = '0';
    const objectiveId = generateUUID();

    await createObjective(objectiveId, name, description, relatedOrg, relatedProject, progress, relatedAnchor);
    if(selectedTemplate) {
      triggerTasks(selectedTemplate, objectiveId); 
    }
  };

  const handleUpdateObjective = async (e) => {
    console.log('ran update objective: ' + props.currentComponentData.objective_id);
    e.preventDefault();
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const objectiveId = props.currentComponentData.objective_id;
    const name = updateObjectiveName;
    const description = updateObjectiveDescription;
    const related_org = currentOrgId;
  
    await updateObjective(objectiveId, name, description, related_org, null);
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateObjective}>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Objective Name"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Objective Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <br />
              {/* -- start add from template */}
              <div>
                <h2>Use an Objective Template?</h2>
                <label>
                  <input
                    type="radio"
                    name="useTemplate"
                    value="yes"
                    onChange={() => setUseTemplate(true)}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="useTemplate"
                    value="no"
                    onChange={() => {
                      setUseTemplate(false);
                      setSelectedTemplate('');
                    }}
                  />
                  No
                </label>

                {useTemplate && (
                  <div>
                    <h3>Select a Template</h3>
                    <select value={selectedTemplate} onChange={handleTemplateChange}>
                      <option value="">-- Select Template --</option>
                      {objectiveTemplates.templateOptions.map((template) => (
                        <option key={template.key} value={template.key}>
                          {template.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div>
                <button onClick={() => checkSelectedTemplate()}>Check selected template</button>
              </div>
              {/* -- end add from template */}
              <br />
              {props.relatedAnchorId &&
                <div className="fx-comp-crud-form-objective-select-anchor-wrap">
                  <label for="assigned-anchor">Assigned Anchor</label>
                  <select id="assigned-anchor" name="assigned anchor" onChange={handleChangeAssignedAnchor}>
                    <option value={props.relatedAnchorId} selected>Current Anchor...</option>
                    {anchorsByProject.map((anchor) => (
                      <option value={anchor.anchor_id}>{anchor.name}</option>
                    ))}
                  </select>
                </div>
                }
              <CompModalButtons>
                <Button type="submit">Create Objective</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              {/*<div>Test: {props.currentComponentData.objective_id}</div>*/}
              <div>
                <div className='fx-global-form-read-only-label'>Objective Name</div>
                <div>{currentObjectiveName}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Objective Description</div>
                <div>{currentObjectiveDescription}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Progress</div>
                <div className='fx-objective-detail-slider-wrap'><Slider defaultValue={props.currentComponentData.progress} aria-label="Default" valueLabelDisplay="auto" /></div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Project</div>
                <div>{props.currentComponentData.project_view_name}</div>
              </div>
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateObjective} className="fx-global-crud-edit-form">
                <div>
                  <TextField id="outlined-basic" label="Objective Name" variant="outlined" value={updateObjectiveName} onChange={handleUpdateObjectiveNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Objective Description"
                  value={updateObjectiveDescription}
                  onChange={handleUpdateObjectiveDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type="submit">Save Objective</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormObjective;