import React, { useEffect, useState } from 'react';
import './CompCRUDFormObjectLink.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createPath, updatePath } from '../fxAPI/fxPathsAPI.js';
import { getPathsByProjectId } from '../fxAPI/fxPathsAPI';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI.js';

const CompCRUDFormObjectLink = (props) => {


  const [error, setError] = useState(null);

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pathResponse, setPathResponse] = useState(null);
  const [pathId, setPathId] = useState(null);
  const [updatePathName, setUpdatePathName] = useState(props.currentComponentData.name);
  const [updatePathDescription, setUpdatePathDescription] = useState(props.currentComponentData.description);

  /* PATHS BY PROJECT ID */
  const [pathsByProjectId, setPathsByProjectData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchPathsByProjectId = async (projectId) => {
    await getPathsByProjectId(projectId, setError, (data) => {
      setPathsByProjectData(data);
      console.log('Fetched paths:', data); // Log fetched data here
    });
  };

  console.log('current path data on form path: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCreateLink = async (event) => {
    event.preventDefault();
    const related_org = localStorage.getItem("fxLocalOrgID");
    const initial_object_id = props.relatedAnchorId;
    const initial_object_type = 'anchor';
    const linking_object_id = pathId;
    const linking_object_type = 'path';
    const relationship_type = '';
    const related_project = props.relatedProject;


    /*
    const parent_path = null;
    const primary_persona = null;
    const favorite = "no";
    const related_project = props.relatedProject;
    */

    await createObjectLink(initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org);
  };

  const handleLinkChange = (e) => {
    setPathId(e.target.value);
  }

  const handleUpdatePathNameChange = (e) => {
    setUpdatePathName(e.target.value);
  }

  const handleUpdatePathDescriptionChange = (e) => {
    setUpdatePathDescription(e.target.value);
  }

  const handleUpdatePath = async (e) => {
    console.log('ran update path: ' + props.currentComponentData.path_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const pathId = props.currentComponentData.path_id;
    const updateName = updatePathName;
    const updateDescription = updatePathDescription;
    const related_org = relatedOrg;
    const parent_path = null;
    const primary_persona = null;
    const favorite = "no";
    const related_project = props.relatedProject;

  
    await updatePath(pathId, updateName, updateDescription, parent_path, primary_persona, favorite, related_org, related_project);
  };

  useEffect(() => {
    const fetchPaths = async () => {
      await fxAPIFetchPathsByProjectId(props.relatedProject);
    };
  
    fetchPaths(); // Call the function to fetch paths
  }, [props.relatedProject]); // Re-run if `props.relatedProject` changes

  useEffect(() => {
    console.log('pathsByProjectId' + props.relatedProject + 'updated:');
    console.dir(pathsByProjectId);
  }, [pathsByProjectId]);

  return (
    <div>
        {props.crudMethod === "create" && props.linkType === "anchor-path" &&
        <div>
          <div>Link Type: {props.linkType}</div>
            <br />
            <form onSubmit={handleCreateLink}>
              <div className='fx-comp-crud-form-object-link-select-anchor-wrap'>
                <label for="paths">Link Path:</label>
                <select name="paths" id="paths" value={pathId} onChange={handleLinkChange}>
                  <option value="">Select Path...</option>
                  {pathsByProjectId.map((path) => (
                    <option key={path.path_id} value={path.path_id}>{path.name}</option>
                  ))}
                </select>
              </div>
              <CompModalButtons>
                <Button type="submit">Link Path</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {/*
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Path Name</div>
                <div>{props.currentComponentData.name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Path Description</div>
                <div>{props.currentComponentData.description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdatePath}>
                <div>
                  <TextField id="outlined-basic" label="Path Name" variant="outlined" value={updatePathName} onChange={handleUpdatePathNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Path Description"
                  value={updatePathDescription}
                  onChange={handleUpdatePathDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save Path</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
          */}
    </div>
  );
};

export default CompCRUDFormObjectLink;