import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import './CompComponent360.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompComponentList from './CompComponentList';
import CompComponentListItem from './CompComponentListItem';
import CompStatusCard from './CompStatusCard';
import CompUIAssetsGallery from './CompUIAssetsGallery';
import CompComponentFilter from './CompComponentFilter';
import { getViewObjectivesProjectsByOrgId, getObjectivesByProjectId, getViewObjectivesProjectsByProjectId } from '../fxAPI/fxObjectivesAPI.js';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { getUserPersonasByOrgId } from '../fxAPI/fxUserPersonasAPI.js';
import { getComStatusByProjectId, getComStatusByOrgId, getComStatusProjectByOrgId } from '../fxAPI/fxStatusAPI.js';
import { getTaskProjectByOrgId, getTasksByProjectId, getTasksByObjectiveId, getTaskProjectByProjectId } from '../fxAPI/fxTasksAPI.js';
import { getUsersByOrgId, getUsersByProjectId } from '../fxAPI/fxUsersAPI.js';
import { getSubjectivesObjectivesByOrgId, getSubjectivesObjectivesByProjectId } from '../fxAPI/fxSubjectivesAPI.js';
import CompListViewActionBar from './CompListViewActionBar';
import CompObjectiveTimeline from './CompObjectiveTimeline.jsx';
import CompQuickCreateObjective from './CompQuickCreateObjective.jsx';
import CompQuickCreateProject from './CompQuickCreateProject.jsx';
import CompObjectiveAnchors from './CompObjectiveAnchors.jsx';
import CompFlowWithSwimlanes from './CompFlowWithSwimlanes.jsx';


const CompComponent360 = (props) => {

  const [error, setError] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  /* Refresh */
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshParent = () => {
    setRefreshKey(prevKey => prevKey + 1); // This will cause a re-render of the parent component
    const currentUserId = localStorage.getItem("fxLocalUserID");
    console.log('component 360 - user: ' + currentUserId);
    fxAPIFetchViewObjectivesByProjectId (props.compId);
  };

  /* APIs */

  /* OBJECTIVES */
  const [viewObjectivesProjectsByOrg, setViewObjectivesProjectsByOrgData] = useState([]); // Initialize as an array
  /* get objectives by orgId */
  const fxAPIFetchViewObjectivesProjectsByOrgId = async (orgId) => {
    await getViewObjectivesProjectsByOrgId(orgId, setError, setViewObjectivesProjectsByOrgData);
    console.log('all view objectives projects by org Id');
    console.dir(viewObjectivesProjectsByOrg);
  };

  /* OBJECTIVES */
  const [viewObjectivesByProjectId, setViewObjectivesByProjectData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchViewObjectivesByProjectId = async (projectId) => {
    await getViewObjectivesProjectsByProjectId(projectId, setError, setViewObjectivesByProjectData);
    console.log('all objectives by project Id');
    console.dir(viewObjectivesByProjectId);
  };

  /* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  /* USER PERSONAS */
  const [userPersonasByOrgId, setUserPersonasByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUserPersonasByOrgId = async (orgId) => {
    await getUserPersonasByOrgId(orgId, setError, setUserPersonasByOrgData);
    console.log('all user personas by org Id');
    console.dir(userPersonasByOrgId);
  };

  /* TASK */
  const [taskProjectByOrgId, setTaskProjectByOrgData] = useState([]); // Initialize as an array
  /* get task - project by orgId */
  const fxAPIFetchTaskProjectByOrgId = async (orgId) => {
    await getTaskProjectByOrgId(orgId, setError, setTaskProjectByOrgData);
    console.log('all task project by org Id');
    console.dir(taskProjectByOrgId);
  };

  const [taskProjectByProjectId, setTaskProjectByProjectData] = useState([]); // Initialize as an array
  /* get task - project by orgId */
  const fxAPIFetchTaskProjectByProjectId = async (projectId) => {
    await getTaskProjectByOrgId(projectId, setError, setTaskProjectByProjectData);
    console.log('all task project by project Id');
    console.dir(taskProjectByProjectId);
  };

  const [tasksByProjectId, setTasksByProjectData] = useState([]); // Initialize as an array
  /* get task - project by orgId */
  const fxAPIFetchTasksByProjectId = async (projectId) => {
    await getTasksByProjectId(projectId, setError, setTasksByProjectData);
    console.log('all tasks by project Id');
    console.dir(tasksByProjectId);
  };

  /* SUBJECTIVES */

  const [subjectivesObjectivesByOrgId, setSubjectivesObjectivesByOrgData] = useState([]); // Initialize as an array
  /* get subjective - objective by orgId */
  const fxAPIFetchSubjectivesObjectivesByOrgId = async (orgId) => {
    await getSubjectivesObjectivesByOrgId(orgId, setError, setSubjectivesObjectivesByOrgData);
    console.log('all subjectives by org Id');
    console.dir(subjectivesObjectivesByOrgId);
  };

  const [subjectivesObjectivesByProjectId, setSubjectivesObjectivesByProjectData] = useState([]); // Initialize as an array
  /* get subjective - objective by projectId */
  const fxAPIFetchSubjectivesObjectivesByProjectId = async (projectId) => {
    await getSubjectivesObjectivesByProjectId(projectId, setError, setSubjectivesObjectivesByProjectData);
    console.log('all subjectives objectives by project Id');
    console.dir(subjectivesObjectivesByProjectId);
  };
  

  /* STATUS */
  const [statusByOrgId, setStatusByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchStatusByOrgId = async (orgId) => {
    await getComStatusByOrgId(orgId, setError, setStatusByOrgData);
    console.log('all status by org Id');
    console.dir(statusByOrgId);
  };

  const [statusByProjectId, setStatusByProjectData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchStatusByProjectId = async (projectId) => {
    await getComStatusByProjectId(projectId, setError, setStatusByProjectData);
    console.log('all status by project Id');
    console.dir(statusByProjectId);
  };

  const [statusProjectByOrgId, setComStatusProjectByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchStatusProjectByOrgId = async (orgId) => {
    await getComStatusProjectByOrgId(orgId, setError, setComStatusProjectByOrgData);
    console.log('all status project by org Id');
    console.dir(statusProjectByOrgId);
  };

  /* USERS */
  const [usersByOrgId, setUsersByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUsersByOrgId = async (orgId) => {
    await getUsersByOrgId(orgId, setError, setUsersByOrgData);
    console.log('all users by org Id');
    console.dir(usersByOrgId);
  };

  const [usersByProjectId, setUsersByProjectData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUsersByProjectId = async (projectId) => {
    await getUsersByProjectId(projectId, setError, setUsersByProjectData);
    console.log('all users by org Id');
    console.dir(usersByProjectId);
  };

  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if(props.type === "projects") {

  }

  const [listViewState, setListViewState] = useState('grid');

  const handleViewActionBarViewChange = (newViewState) => {
    setListViewState(newViewState);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
    fxAPIFetchProjectsByOrgId(currentOrgId);
    fxAPIFetchUserPersonasByOrgId(currentOrgId);
    fxAPIFetchStatusByOrgId(currentOrgId);
    fxAPIFetchStatusByProjectId(props.compId);
    fxAPIFetchViewObjectivesByProjectId (props.compId);
    fxAPIFetchTaskProjectByOrgId(currentOrgId);
    fxAPIFetchStatusProjectByOrgId(currentOrgId);
    fxAPIFetchUsersByOrgId(currentOrgId);
    fxAPIFetchUsersByProjectId(props.compId);
    fxAPIFetchTasksByProjectId(props.compId);
    fxAPIFetchTaskProjectByProjectId(props.compId);
    fxAPIFetchSubjectivesObjectivesByOrgId(currentOrgId);
    fxAPIFetchSubjectivesObjectivesByProjectId(props.compId);
  }, []);

  return (
    <div className="fx-comp-component-360-outer" key={refreshKey}>
        {/*Comp ID: {props.compId}
        {props.children}
  {props.isSingle}*/}
        {props.type === "projects" && props.isSingle === "all" &&
          <>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="All Projects" />
              <Tab label="Objectives" />
              <Tab label="Subjectives" />
              <Tab label="Status" />
              <Tab label="Team Members" />
              
            </Tabs>

            {tabValue === 0 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Project" itemType="project" crudMethod="create" />
                <CompComponentList listName="Projects">
                  {projectsByOrgId.map((projectItem) => (
                    <CompComponentListItem itemType="project" relatedComponentType="none" itemLabel={projectItem.name} componentData={projectItem}/>
                  ))}
                </CompComponentList>
              </Box>
            )}

            {tabValue === 1 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Objective" itemType="objective" crudMethod="create" />
                <CompComponentList listName="Objectives">
                {viewObjectivesProjectsByOrg.map((viewObjective) => (
                  <CompComponentListItem itemType="objective" itemLabel={viewObjective.objective_view_name} itemProgress={viewObjective.progress} objectiveProjectLabel={viewObjective.project_view_name} componentData={viewObjective} componentId={viewObjective.objectiveId} />
                ))}
                </CompComponentList>
              </Box>
            )}

            {tabValue === 2 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Subjective" itemType="subjective" crudMethod="create" />
                <CompComponentList listName="Subjectives">
                {subjectivesObjectivesByOrgId.map((subjective) => (
                  <CompComponentListItem itemType="subjective" relatedComponentType="project" itemLabel={subjective.subjective_view_name} componentData={subjective} />
                ))}
                </CompComponentList>
              </Box>
            )}

            {tabValue === 3 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Project Status Request" itemType="status" crudMethod="create" />
                {statusProjectByOrgId.map((status) => (
                  <CompStatusCard statusTitle={status.status_view_title} relatedComponentType="project" statusDescription={status.status_view_description} componentData={status}/>
                ))}
              </Box>
            )}

            
              {tabValue === 4 && (
                <Box p={3} className="fx-">
                  <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Team Member" itemType="team-member" crudMethod="create" />
                  <CompComponentList listName="Team Members">
                  {usersByOrgId.map((user) => (
                    <CompComponentListItem itemType="team-member" relatedComponentType="project" itemLabel={`${user.first_name} ${user.last_name}`} componentData={user} />
                  ))}
                  </CompComponentList>
                </Box>
              )}
          </>
        }

        {props.type === "projects" && props.isSingle === "single" &&
          <>
          <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Objective Map" />
              <Tab label="Paths" />
              <Tab label="UI Assets" />              
            </Tabs>

          {tabValue === 0 && (
            <>
              <CompObjectiveAnchors relatedProject={props.compId} refreshParent={refreshParent} updatePanelContext={(contextType, objectId) => props.updatePanelContext(contextType, objectId)} />      
            </>
          )}
          {tabValue === 1 && (
            <CompFlowWithSwimlanes relatedProject={props.compId} />
          )}
          {tabValue === 2 && (
            <CompUIAssetsGallery relatedProject={props.compId} />
          )}
          
          {/* 
          <CompObjectiveTimeline relatedProject={props.compId} refreshParent={refreshParent} updatePanelContext={(contextType, objectId) => props.updatePanelContext(contextType, objectId)} />
          */}
          {/*
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Objectives" />
              <Tab label="Subjectives" />
              <Tab label="Status" />
              <Tab label="Team Members" />
              
            </Tabs>

            {tabValue === 0 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Objective" itemType="objective" crudMethod="create" />
                <CompComponentList listName="Objectives">
                {viewObjectivesByProjectId.map((viewObjective) => (
                  <CompComponentListItem itemType="objective" relatedComponentType="none" itemLabel={viewObjective.objective_view_name} itemProgress={viewObjective.progress} objectiveProjectLabel={viewObjective.project_view_name} componentData={viewObjective} componentId={viewObjective.objectiveId} />
                ))}
                </CompComponentList>
                <div>
                <CompQuickCreateObjective relatedProject={props.compId} refreshParent={refreshParent} />
                </div>
              </Box>
            )}

            {tabValue === 1 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Subjective" itemType="subjective" crudMethod="create" />
                <CompComponentList listName="Subjectives">
                {subjectivesObjectivesByProjectId.map((subjective) => (
                  <CompComponentListItem itemType="subjective" relatedComponentType="project" itemLabel={subjective.subjective_view_name} componentData={subjective} />
                ))}
                </CompComponentList>
              </Box>
            )}

            {tabValue === 2 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Project Status Request" itemType="status" crudMethod="create" />
                  {statusByProjectId.map((comStatus) => (
                  <CompStatusCard statusTitle={comStatus.title} relatedComponentType={comStatus.related_component_type} statusDescription={comStatus.description} componentData={comStatus} />
                ))}
              </Box>
            )}

            
              {tabValue === 3 && (
                <Box p={3} className="fx-">
                  <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Team Member" itemType="team-member" crudMethod="create" />
                  <CompComponentList listName="Team Members">
                  {usersByProjectId.map((user) => (
                    <CompComponentListItem itemType="team-member" relatedComponentType="project" itemLabel={`${user.first_name} ${user.last_name}`} componentData={user} />
                  ))}
                  </CompComponentList>
                </Box>
              )}
            */}
          </>
        }

        {props.type === "user-personas" &&
          <>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="All User Personas" />
              
            </Tabs>

            {tabValue === 0 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create User Persona" itemType="user-persona" crudMethod="create" />
                <CompComponentList listName="User Personas">
                  {userPersonasByOrgId.map((userPersona) => (
                    <CompComponentListItem itemType="user-persona" relatedComponentType="user-persona" itemLabel={userPersona.name} componentId={userPersona.user_persona_id} componentData={userPersona}/>
                  ))}
                </CompComponentList>
              </Box>
            )}
          </>
        }   

        {props.type === "objectives" &&
          <>
          {/*<CompComponentFilter></CompComponentFilter>*/}
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="All Objectives" />
              <Tab label="Objective Timeline" />
            </Tabs>

            {tabValue === 0 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Objective" itemType="objective" crudMethod="create" />
                <CompComponentList listName="Objectives">
                {viewObjectivesProjectsByOrg.map((viewObjective) => (
                  <CompComponentListItem itemType="objective" itemLabel={viewObjective.objective_view_name} itemProgress={viewObjective.progress} objectiveProjectLabel={viewObjective.project_view_name} componentId={viewObjective.objective_id} componentData={viewObjective}/>
                ))}
                </CompComponentList>
              </Box>
            )}

            {tabValue === 1 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Objective" itemType="objective" crudMethod="create" />
                <CompObjectiveTimeline />
              </Box>
            )}
          </>
        } 

        {props.type === "ui-assets" &&
          <>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="All UI Assets" />
              
            </Tabs>

            {tabValue === 0 && (
              <Box p={3} className="fx-">
                <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create UI Asset" itemType="ui-asset" crudMethod="create" />
                <CompUIAssetsGallery />
              </Box>
            )}
            
          </>
        } 

        {props.type === "status" &&
          <>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="All Status" />
              
            </Tabs>

            {tabValue === 0 && (
              <Box p={3} className="fx-">
                    <CompListViewActionBar onViewChange={handleViewActionBarViewChange} actionModalTitle="Create Status Request" itemType="status" crudMethod="create" />
                    {statusByOrgId.map((statusItem) => (
                      <CompStatusCard statusTitle={statusItem.title} statusDescription={statusItem.description} componentData={statusItem}/>
                    ))}
              </Box>
            )}
          </>
        } 
        
    </div>
  );
};

export default CompComponent360;