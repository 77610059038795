import React, { useEffect, useState, useContext } from 'react';
import './CompLinkIntModal.css'; // Create this CSS file for styling
import axios from "axios";
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridToolbar,
} from "@mui/x-data-grid";
import { Box, CircularProgress } from "@mui/material";
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI';

import { PanelContext } from '../Contexts/PanelContext';


const CompCRUDModal = ({ isOpen, onClose, children, modalTitle, modalComponentData, itemType, crudMethod, relatedComponentId, relatedProject, relatedAnchorId, linkType, relatedObjectType, closeModal, integrationType }) => {
  const [error, setError] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const { triggerElementRefresh } = useContext(PanelContext);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIssueIds, setSelectedIssueIds] = useState([]);

  const fetchIssues = async () => {
    try {
      const response = await axios.post("/api/jira-api/issues-list", {
        endpoint: "search",
      });

      // Extract and map the issues for the table
      const issuesData = response.data.issues.map((issue) => ({
        id: issue.id,
        key: issue.key,
        summary: issue.fields.summary,
        status: issue.fields.status.name,
        assignee: issue.fields.assignee?.displayName || "Unassigned",
      }));

      setIssues(issuesData);
    } catch (error) {
      console.error("Error fetching Jira issues:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssues();
  }, []);

  const handleSelectionChange = (newSelection) => {
    setSelectedIssueIds(newSelection); // Update selected IDs
  };

  const bulkLink = () => {
    console.log("Processing selected issues:");
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    selectedIssueIds.forEach((issueId) => {
      const issue = issues.find((item) => item.id === issueId);
      if (issue) {
        console.log(issue);
        // Add bulk action logic here
        if(itemType === "objective") {
          var initialObjectId = modalComponentData.objective_id;
        }
        const initial_object_id = initialObjectId;
        const initial_object_type = itemType;
        const linking_object_id = issue.key;
        const linking_object_type = "jira-issue";
        const relationship_type = "";
        const related_project = relatedProject;
        const related_org = currentOrgId;
        //console.log('committing object link: ' + initial_object_id + " " + initial_object_type + " " + linking_object_id + " " + linking_object_type + " " + relationship_type + " " + related_project + " " + related_org);
        createObjectLink(initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org)
      }
    });
  };

  const columns = [
    { field: "key", headerName: "Issue Key", width: 150 },
    { field: "summary", headerName: "Summary", flex: 1 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "assignee", headerName: "Assignee", width: 200 },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  /* use global context */
  /*
  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = 'subjectivePanel';

  // Handle refresh for the specific element
  const handleRefresh = async () => {
    console.log('Refreshing subjective panel data');
    resetElementRefresh(elementId); // Reset the refresh flag after completion
  };
  */

  const handleClose = () => {
    closeModal();  // This will close the modal
    //handleRefresh(); // Trigger the refresh only when needed
  };
  

  if (!isOpen) return null; // Early return if modal is not open

  console.log('Component data on modal:');
  console.dir(modalComponentData);
  /*
  if (itemType === "project") {
    console.log('project_id: ' + modalComponentData.project_id); 
  }
  */


  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>

        <button className="modal-close" onClick={onClose}>X</button>

        <h2>{modalTitle}</h2>
        {children}
        Component ID: {relatedComponentId}
        Integration Type: {integrationType}
        Related Object Type: {itemType}

        {integrationType === "jira-issue" &&
        <>
          <Box sx={{ height: "100%", width: "100%" }}>
            
            <DataGrid
              rows={issues}
              columns={columns}
              components={{
                Toolbar: GridToolbar,
              }}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionChange} // Correct API for selection
              pageSize={10}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
            />
          </Box>
          <div className="fx-comp-link-int-modal-action-button-row">
            <Button onClick={handleClose}>
              Close
            </Button>
            <Button
                variant="outlined"
                onClick={bulkLink}
                disabled={selectedIssueIds.length === 0}
                sx={{ marginBottom: 2 }}
              >
                Link Issues
              </Button>
          </div>
        </>
        }

        


      </div>
    </div>
  );
};

export default CompCRUDModal;