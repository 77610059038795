import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageIntegrationTest.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getIntegrationsByOrgId } from '../fxAPI/fxIntegrationsAPI.js';


const PageIntegrationTest = (props) => {

  const [error, setError] = useState(null);

  const [storedTeamsUser, setStoredTeamsUser] = useState(null);

  const getUserIdByEmail = async (email) => {
    const accessToken = sessionStorage.getItem('msTeamsAccessToken'); // Retrieve the access token from session storage

    try {
        const response = await axios.get(`https://graph.microsoft.com/v1.0/users?$filter=mail eq '${email}'`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.data.value.length > 0) {
            return response.data.value[0].id; // Return the first user's ID found
        } else {
            throw new Error('User not found');
        }
    } catch (error) {
        console.error('Error fetching user ID:', error.response ? error.response.data : error.message);
        throw new Error('Failed to fetch user ID');
    }
};

const sendMessage = async (email, messageContent) => {
  try {
      // Step 1: Get User ID by Email
      const userId = await getUserIdByEmail(email);

      // Step 2: Send the message using the retrieved User ID
      const accessToken = sessionStorage.getItem('msTeamsAccessToken');

      const response = await axios.post('https://fluxity.io/api/messages/send', {
          userId: userId,
          message: messageContent,
      }, {
          headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
          },
      });

      console.log('Message sent successfully:', response.data);
  } catch (error) {
      console.error('Error sending message:', error.response ? error.response.data : error.message);
  }
};

  /* ----- MS Teams ----- */
  const handleLogin = () => {
    window.location.href = `https://fluxity.io/api/auth/login`;
};

useEffect(() => {
  const currentTeamsUser = sessionStorage.getItem("msTeamsUserData");
  setStoredTeamsUser(currentTeamsUser);
  console.log('Saved MS Teams user: ' + currentTeamsUser);
}, []);

const handleSendMessage = () => {
  const email = 'tjoseph@uxintegrity.com'; // Replace with the actual email address you want to send a message to
  const messageContent = 'Hello from React 3 - Static!'; // Your message content in Markdown
  sendMessage(email, messageContent);
};

/* --- Adaptive Card --- */

const getChatId = async (userEmail) => {
  const accessToken = sessionStorage.getItem('msTeamsAccessToken');
  try {
    const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${userEmail}/chats`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.data.value.length > 0) {
      return response.data.value[0].id;  // Return the first chat ID found
    } else {
      throw new Error('Chat not found');
    }
  } catch (error) {
    console.error('Error fetching chat ID:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch chat ID');
  }
};

/*
const handleSendCard = async () => {
  const token = sessionStorage.getItem('msTeamsAccessToken');

  if (!token) {
      alert('No access token found. Please authenticate first.');
      return;
  }

  try {
      const chatId = getChatId('tjoseph@uxintegrity.com');  // You need to specify the correct chatId
      // If the chatId is not known, you might need to fetch it using the Microsoft Graph API.

      // Make the POST request to the server
      const response = await fetch('https://207.211.176.95/api/sendAdaptiveCard', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              chatId: chatId,  // Add chatId here
              accessToken: token // Pass the token for authorization
          })
      });

      if (response.ok) {
          const result = await response.json();
          console.log(result.message);
          alert('Adaptive Card sent successfully!');
      } else {
          console.error('Failed to send Adaptive Card');
          alert('Failed to send Adaptive Card');
      }
  } catch (error) {
      console.error('Error:', error);
      alert('Error sending Adaptive Card');
  }
};
*/


const handleSendCard = async () => {
  const accessToken = sessionStorage.getItem('msTeamsAccessToken');
  const currentChatId = getChatId('tjoseph@uxintegrity.com')
  sendAdaptiveCardFromReact('b4c9de04-f4d7-4f93-a94f-d9f24b411ab3', accessToken);
};

const sendAdaptiveCardFromReact = async (chatId, accessToken) => {
  const adaptiveCard = {
    "body": {
      "contentType": "application/vnd.microsoft.card.adaptive",
      "content": {
        "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
        "type": "AdaptiveCard",
        "version": "1.4",
        "body": [
          {
            "type": "TextBlock",
            "text": "Hello from Adaptive Card!"
          }
        ]
      }
    }
  };
  
  const response = await fetch(
    `https://graph.microsoft.com/v1.0/chats/${chatId}/messages`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "body": {
          "contentType": "application/vnd.microsoft.card.adaptive",
          "content": JSON.stringify(adaptiveCard)
        }
      })
    }
  );
  
  const result = await response.json();
  console.log('Adaptive Card sent successfully:', result);
};

/* JIRA */



const fetchJiraData = async () => {
  try {
    const response = await axios.post('https://fluxity.io/api/jira-api/issues-list', {
      endpoint: 'search?jql=project=FLUX', // Example: get all issues from the UX project
      method: 'GET',
    });

    // Log the response to the console
    console.log('Jira Issues:', response.data);
  } catch (error) {
    console.error('Error fetching Jira issues:', error);
  }
};


  /* -- JIRA -- */
  const [applications, setApplications] = useState([]);
  
  //const [projects, setProjects] = useState([]);

  useEffect(() => {
    const jiraTestEmail = 'tjoseph@uxintegrity.com';
    const jiraTestAPIToken = 'ATCTT3xFfGN0qgjKxTfJXhAUi5YMwFefLAQ-nSK5O5UXaaVPK6uZxXEusqIAILyp9pxyklCQ7i8MptcyvplDlsVlBFjf12F4Ybe3AhXkCaJB_QNtKIaHaPOVzHt4tbKnZA-VsUz9p0URTIHOf3eM80savxOTxZr7JKs8SKCthbsp1PAL_HEEVxM=7AD714A0';
    const jiraTestURL = 'https://ux-integrity.atlassian.net/rest/api/3';

    const fetchProjects = async () => {
      // Encode the email and API token to Base64
      const auth = btoa(`${jiraTestEmail}:${jiraTestAPIToken}`);

      try {
        const response = await axios.get(`${jiraTestURL}/search?jql=project=FLUX`, {
          headers: {
            'Authorization': `Basic ${auth}`,
            'Content-Type': 'application/json',
          },
        });
        console.log('test pinged Jira:');
        console.dir(response.data);
        //setProjects(response.data);
      } catch (err) {
        setError(err);
        console.error("Error fetching projects:", err);
      }
    };

    fetchProjects();
  }, []);


  return (
    <CompPageTempMaster3Panel headingOne="Integrations">

        <div>
            <button onClick={handleLogin}>Login with Microsoft</button>

            <button onClick={handleSendMessage}>Send Message</button>

            <button onClick={handleSendCard}>Send Adaptive Card</button>

            <button onClick={fetchJiraData}>Test Jira Data</button>

            
        </div>

        
      
    </CompPageTempMaster3Panel>
  );
}

export default PageIntegrationTest;