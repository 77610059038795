import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthMSTeamsCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const getQueryParams = () => {
            const params = new URLSearchParams(window.location.search);
            return {
                code: params.get('code'),
                sessionState: params.get('session_state'),
            };
        };

        const handleAuthCallback = async () => {
            const { code, sessionState } = getQueryParams();

            // Check if the authorization code exists
            if (code) {
                try {
                    // Exchange the authorization code for an access token
                    const response = await fetch(`https://fluxity.io/api/auth/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ code, sessionState }),
                    });

                    if (!response.ok) {
                        throw new Error('Failed to retrieve access token');
                    }

                    const data = await response.json();
                    console.log('Access Token:', data.accessToken); // Optional: for debugging

                    // Store the access token and user data in sessionStorage
                    sessionStorage.setItem('msTeamsUserData', JSON.stringify(data.user));
                    sessionStorage.setItem('msTeamsAccessToken', data.accessToken);

                    // Redirect to the home page or dashboard
                    navigate('/integration-test');  // Change to your desired path
                } catch (error) {
                    console.error('Error during authentication:', error);
                    // Optionally, navigate to an error page or show a message
                }
            } else {
                console.error('Authorization code not found in the URL');
                // Handle the case where the authorization code is not present
                navigate('/login'); // Redirect to login or handle error
            }
        };

        handleAuthCallback();
    }, [navigate]);

    return null;  // Return null to avoid displaying anything
};

export default AuthMSTeamsCallback;