import React, { createContext, useState, useCallback } from 'react';

// Create the context
export const PanelContext = createContext();

// Create the provider component
export const PanelProvider = ({ children }) => {
    const [elements, setElements] = useState({});
  
    // Function to set a specific element's refresh status
    const triggerElementRefresh = useCallback((elementId) => {
      setElements(prevElements => ({
        ...prevElements,
        [elementId]: {
          ...prevElements[elementId],
          refresh: true
        }
      }));
    }, []);
  
    // Function to reset the refresh status for a specific element
    const resetElementRefresh = useCallback((elementId) => {
      setElements(prevElements => ({
        ...prevElements,
        [elementId]: {
          ...prevElements[elementId],
          refresh: false
        }
      }));
    }, []);
  
    return (
      <PanelContext.Provider value={{
        elements,
        triggerElementRefresh,
        resetElementRefresh
      }}>
        {children}
      </PanelContext.Provider>
    );
  };


/* 
Old context model
export const PanelProvider = ({ children }) => {
    const [panelContext, setPanelContext] = useState("Default context");
    const [panelObjectId, setPanelObjectId] = useState("#");
    const [panelObject, setPanelObject] = useState({});

    // Define the function to update the context
    const updatePanelContext = (contextType, objectId, object) => {
        console.log("Updating panel context in parent:", { contextType, objectId, object });
        setPanelContext(contextType);
        setPanelObjectId(objectId);
        setPanelObject(object);
    };

    // Provide the context values to children
    return (
        <PanelContext.Provider value={{ panelContext, panelObjectId, panelObject, updatePanelContext }}>
            {children}
        </PanelContext.Provider>
    );
};
*/