import React, { useState, useEffect, useContext } from 'react';
import './CompQuickCreateObjective.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI';
import { createSubjective } from '../fxAPI/fxSubjectivesAPI.js';
import { PanelContext } from '../Contexts/PanelContext';


const CompQuickCreateSubjective = (props) => {

  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [newProjectId, setNewProjectId] = useState(''); // Initialize with empty string
  const [newSubjectiveName, setNewSubjectiveName] = useState(''); // Initialize with empty string
  const [newSubjectiveDescription, setNewSubjectiveDescription] = useState(''); // Initialize with empty string

  function handleToggleItem(toggleValue) {
    setIsExpanded(toggleValue);
    console.log('toggle: ' + toggleValue);
  }

  /* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  const handleSubjectiveNameChange = (e) => {
    setNewSubjectiveName(e.target.value);
  };

  const handleSubjectiveDescriptionChange = (e) => {
    setNewSubjectiveDescription(e.target.value);
  };

  const handleCreateSubjective = async (event) => {
    event.preventDefault();
  
    const related_org = localStorage.getItem("fxLocalOrgID");
  
    const name = newSubjectiveName;
    const description = newSubjectiveDescription;
    const related_objective = props.relatedObjective;
    const related_project = props.relatedProject;
  
    // Log values to verify they are correct
    console.log("Creating subjective with values: ", {
      name, description, related_org, related_objective, related_project
    });
  
    try {
      await createSubjective(name, description, related_org, related_objective, related_project);
      console.log('Subjective Created successfully!');
      handleRefreshElement();
    } catch (error) {
      console.error('Error creating subjective:', error);

      if (error.message.includes('Parse Error')) {
        console.error('Specific Parse Error occurred:', error);
      }
    } finally {
      // Ensure that the parent is refreshed, regardless of the error
      props.refreshParent();
    }

  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchProjectsByOrgId(currentOrgId);
  }, []);

  /* Use Global Context to refresh Subjective Panel */
  const { triggerElementRefresh } = useContext(PanelContext);

  const handleRefreshElement = () => {
    triggerElementRefresh('subjectivePanel'); // Trigger refresh for the subjectivePanel
  };

  return (
      <div>
          <div className='fx-comp-quick-create-subjective-form-header'>
            {/*
            <div>
              Subjective Quick Create for {props.relatedProject}
            </div>
            */}
          </div>
              <Box p={3} className="fx-">
                <form className='fx-comp-quick-create-subjective-form' onSubmit={handleCreateSubjective}>
                  <div className='fx-comp-quick-create-subjective-fields-row'>
                    <div>
                      <TextField id="outlined-basic" label="Title" variant="outlined" className='fx-comp-quick-create-subjective-related-title-field' onChange={handleSubjectiveNameChange} />
                    </div>
                    <div className="fx-comp-quick-create-subjective-text-area-description">
                      <br />
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Description"
                      multiline
                      maxRows={4}
                      fullWidth
                      onChange={handleSubjectiveDescriptionChange}
                      className='fx-comp-quick-create-subjective-related-description-field'
                      />  
                    </div>
                    <br />
                    <div>
                      <Button variant='outlined' type="submit">Create Subjective</Button>   
                    </div>
                  </div>
                </form>
              </Box>
        </div>

  );
};

export default CompQuickCreateSubjective;