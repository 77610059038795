import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageDashboard.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import Button from '@mui/material/Button';
import axios from 'axios';
import CompComponent360 from '../Components/CompComponent360';
import CompDashboardTables from '../Components/CompDashboardTables';
import CompDashboardMetrics from '../Components/CompDashboardMetrics';
import CompDashCardGallery from '../Components/CompDashCardGallery';
import CompObjectiveTable from '../Components/CompObjectiveTable';
import ChildOne from '../Components/ChildOne';
import CompActionTracker from '../Components/CompActionTracker';
import CompMilestones from '../Components/CompMilestones';

const PageDashboard = (props) => {

  const params = useParams();

  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // State for success message
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [projectName, setProjectName] = useState('');
  
  const [projects, setProjectsData] = useState('');

/* get projects by orgId */
const getProjectsByOrgId = async (orgId) => {
  setError(null);
  setProjectsData(null); // Assuming you meant to set projects data, not user data

  if (!orgId) {
    setError('OrgId parameter is required');
    return;
  }

  try {
    console.log(`Fetching project data for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/projects/projects-by-org', {
      params: { orgId }
    });
    console.log('API response received');
    setProjectsData(response.data);
    console.log('project data: ');
    console.dir(response.data);
    console.log('Individual project Name: ' + response.data.items[0].name);
    setProjectName(response.data.items[0].name);

  } catch (error) {
    console.error('Error fetching projects data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch projects data');
  }
};

useEffect(() => {

  const currentOrgId = localStorage.getItem("fxLocalOrgID");
  if (currentOrgId) {
    console.log(`Current OrgId from local storage: ${currentOrgId}`);
    getProjectsByOrgId(currentOrgId);
  } else {
    setError('No OrgId found in local storage');
  }
}, []);


  const storedUserFirstName = localStorage.getItem("fxLocalUserFirstName");

  function welcomingMessage() {
    const storedLocalUserName = localStorage.getItem("fxLocalUserName");

    if(params.type === "projects" && params.compId === "all") {
      var welcomeMessageItem = "All Playbooks";
    } else if (params.type === "projects" && params.compId !== "all") {
      var welcomeMessageItem = "Playbook: " + projectName;
    } else if (params.type === "role") {
      var welcomeMessageItem = "Welcome " + storedLocalUserName;
      /*
      if(params.compId === "c-level") {
        var welcomeMessageItem = "Welcome Executive";
      } else if(params.compId === "scrum-master") {
        var welcomeMessageItem = "Welcome Scrum Master";
      } else if(params.compId === "business-analyst") {
        var welcomeMessageItem = "Welcome Business Analyst";
      } else if(params.compId === "developer") {
        var welcomeMessageItem = "Welcome Developer";
      } else if(params.compId === "ux-designer") {
        var welcomeMessageItem = "Welcome UX Designer";
      }*/

    } else if(params.type === "objectives") {
      var welcomeMessageItem = "Objectives";
    } else if(params.type === "user-personas") {
        var welcomeMessageItem = "User Personas";
    } else if(params.type === "ui-assets") {
        var welcomeMessageItem = "UI Assets";
    } else if(params.type === "status") {
        var welcomeMessageItem = "Status";
    }  
    return welcomeMessageItem;
  }

  function toolsSubHeadingGeneration() {
    if(params.type === "projects") {
      var subHeading = "Playbook Overview";
    } else if(params.type === "objectives") {
      var subHeading = "Objectives Overview";
    } else if(params.type === "user-personas") {
        var subHeading = "User Personas Overview";
    } else if(params.type === "ui-assets") {
        var subHeading = "User Assets Overview";
    } else if(params.type === "status") {
        var subHeading = "Status Overview";
    }
    return subHeading;
  }

  const welcomeMessage = welcomingMessage();
  const toolsSubHeading = toolsSubHeadingGeneration();

  function isSingleTest() {
    if(params.compId !== "all") {
      var singleTest = "single";
    } else {
      var singleTest = "all";
    }
    return singleTest;
  }
  

  const isSingle = isSingleTest();

  const milestones = [
    {
      label: "Milestone 1",
      dates: ["2024-12-01", "2024-12-05"], // Start and end date
    },
    {
      label: "Milestone 2",
      dates: ["2024-12-10", "2024-12-15"],
    },
    {
      label: "Milestone 3",
      dates: ["2024-12-20", "2024-12-25"],
    },
    {
      label: "Milestone 4",
      dates: ["2024-12-05", "2024-12-10"],
    },
  ];

  return (
    <CompPageTempMaster3Panel headingOne={welcomeMessage}>

      {/*<CompDashCardGallery galleryType="playbook" />*/}

      {/*{params.compId}*/}
      {/*
      {params.type === "projects" &&
      <CompContentBlock headingThree="Screens">
        <CompFeatureCarousel projectId={params.compId} />
      </CompContentBlock>
      }
      */}
      <CompContentBlock headingThree="Milestones">
        <CompMilestones milestones={milestones} />
      </CompContentBlock>

      {params.type !== "role" &&
      <CompContentBlock headingThree={toolsSubHeading}>
        <CompComponent360 type={params.type} isSingle={isSingle} compId={params.compId} updatePanelContext={(contextType, objectId, object) => props.updatePanelContext(contextType, objectId, object)}></CompComponent360>
      </CompContentBlock>
      }

      {params && params.type === "role" && (
        <CompContentBlock headingThree="Playbooks" width="full" background="light" variant="uxc-variant-default">
        <CompDashCardGallery
          galleryType="playbook"
          updatePanelContext={(contextType, objectId, object) => props.updatePanelContext(contextType, objectId, object)}
        />
      </CompContentBlock>
      )}

      {params.type === "role" &&
        <CompContentBlock headingThree="Objectives">
          {/*<CompDashboardTables />*/}
          <CompObjectiveTable updatePanelContext={(contextType, objectId, object) => props.updatePanelContext(contextType, objectId, object)} />
        </CompContentBlock>
      }

      {params.type === "role" &&
        <CompContentBlock headingThree="Action Tracker">
          <CompActionTracker />
        </CompContentBlock>
      }

      {params.type === "role" &&
        <CompContentBlock headingThree="Personas">
          <CompDashCardGallery galleryType="persona" />
        </CompContentBlock>
      }

      {params.type === "role" &&
        <CompContentBlock headingThree="Integrations">
          <CompDashCardGallery galleryType="integration" />
        </CompContentBlock>
      }

      
    </CompPageTempMaster3Panel>
  );
}

export default PageDashboard;