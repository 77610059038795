import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageIntegrations.css';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompCollapsibleContainer from '../Components/CompCollapsibleContainer.jsx';
import { getIntegrationsByOrgId } from '../fxAPI/fxIntegrationsAPI.js';
import { fetchCaseTypes, fetchCaseTypeDetail, fetchFlowActionsByClass } from '../fxAPI/fxIntPegaAPI.js';

const PageIntegrations = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [caseTypes, setCaseTypes] = useState([]);
  const [caseTypeDetail, setCaseTypeDetail] = useState(null);
  const [integrationsByOrgId, setIntegrationsByOrgData] = useState([]);

  const [flowActions, setFlowActionsData] = useState([]);

  const caseTypeID = 'OY9DDY-Centered-Work-CenterDemo';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentOrgId = localStorage.getItem("fxLocalOrgID");
        await getIntegrationsByOrgId(currentOrgId, setError, setIntegrationsByOrgData);

        const caseTypesData = await fetchCaseTypes();
        setCaseTypes(caseTypesData.caseTypes || []);

        const caseDetail = await fetchCaseTypeDetail(caseTypeID);
        setCaseTypeDetail(caseDetail);
        console.log('case detail: ');
        console.dir(caseDetail);

        const flowActionsData = await fetchFlowActionsByClass('D_FXFlowActionsByClassList', 'OIWMAC-FluxityInt-Work-FluxitySample');
        setFlowActionsData(flowActionsData || []);
        console.log('flow actions: ');
        console.dir(flowActionsData);
        console.dir(flowActions);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading data...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>Error: {error}</p>;
  }

  return (
    <CompPageTempMaster3Panel headingOne="Integrations">
      <CompContentBlock headingThree="Integration 1: ...">
        Integrations... Test
        {integrationsByOrgId.map((integrationItem) => (
          <div key={integrationItem.integration_id}>{integrationItem.integration_id}</div>
        ))}
      </CompContentBlock>

      <CompCollapsibleContainer containerHeading="Integration 1">
        Integration form here...
      </CompCollapsibleContainer>

      <div>
        <h1>Case Types</h1>
        <ul>
          {caseTypes.map((caseType, index) => (
            <li key={index}>
              {caseType.name} (ID: {caseType.ID})
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h1>Case Type Detail: {caseTypeDetail.name}</h1>
        <p>Description: {caseTypeDetail.description}</p>
      </div>

      <div>
        <h1>Flow Actions: </h1>
        <div>
          {flowActions.pxResults.map((flowAction, index) => (
            <li key={index}>
              Name: {flowAction.pyActionName}
            </li>
          ))}
        </div>
      </div>
    </CompPageTempMaster3Panel>
  );
};

export default PageIntegrations;
