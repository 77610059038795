import React, { useState, useEffect, useContext } from 'react';
import './CompSideContextPanel.css';
import { PanelContext } from '../Contexts/PanelContext';
import Button from '@mui/material/Button';
import CompSubjectivePanel from './CompSubjectivePanel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import avatars from '../Data/avatars.json';
import CompUserAssignment from './CompUserAssignment';

const CompSideContextPanel = (props) => {

    const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = 'sideContextPanel';

    //localStorage.setItem("fxSidePanelContext", "project");
  //localStorage.setItem("fxSidePanelObject", "test object");

  const currentSidePanelContext = localStorage.getItem("fxSidePanelContext");
  const currentSidePanelObjectId = localStorage.getItem("fxSidePanelObjectId");
  const currentSidePanelObject = JSON.parse(localStorage.getItem("fxSidePanelObject"));

   // Handle refresh for the specific element
   const handleRefresh = async () => {
    console.log('Refreshing context panel data');
    resetElementRefresh(elementId); // Reset the refresh flag after completion
  };

  const unsetPanel = async () => {
    localStorage.removeItem("fxSidePanelContext");
    localStorage.removeItem("fxSidePanelObjectId");
    localStorage.removeItem("fxSidePanelObject");
    handleRefresh('sideContextPanel');
  };

  // Effect to handle refresh logic when the flag changes
  useEffect(() => {
    if (elements[elementId]?.refresh) {
      handleRefresh(); // Trigger the refresh only when needed
      triggerElementRefresh(elementId); // Re-set refresh flag to prevent re-triggering
      console.log('displaying object on side panel 1: ');
      console.dir(currentSidePanelObject);
    }
  }, [elements[elementId]?.refresh, triggerElementRefresh, resetElementRefresh]);

  const avatar = avatars.avatars.find(a => {
    const relatedAvatarId = currentSidePanelObject?.related_avatar || null;
    return a.id === relatedAvatarId;
  });
  

  return (
    <div>
        {localStorage.getItem("fxSidePanelContext") && (
                <>
                <div><Button onClick={unsetPanel}>Dismiss</Button></div>
                {/* 
                <h2>Item Focus</h2>
                <div>Test Current Panel: {currentSidePanelContext}</div>
                <div>Test Current Object: {currentSidePanelObjectId}</div>
                */}
                {currentSidePanelContext === "objective" && 
                <>
                  <h2>Objective Focus</h2>
                  <div>
                    <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                    <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || currentSidePanelObject.objective_view_name || null}</div>
                    <hr />
                  </div>
                  <div>
                    <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                    <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || currentSidePanelObject.objective_view_description || null}</div>
                    <hr />
                  </div>
                  <div>
                    <div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Date Created</div>
                        <CalendarTodayIcon /> {currentSidePanelObject.date_time_created || null}
                      </div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Date Due</div>
                        <CalendarTodayIcon /> {currentSidePanelObject.date_due || null}
                      </div>
                    </div>
                    <hr />
                  </div>
                  </>
                }
                {currentSidePanelContext === "subjective" &&
                  <>
                    <h2>Subjective Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || currentSidePanelObject.subjective_view_name || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || currentSidePanelObject.subjective_view_description || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Assigned To</div>
                        <CompUserAssignment userAssigned={currentSidePanelObject.user_assigned} />
                      </div>
                      <hr />
                    </div>
                  </>
                }
                {currentSidePanelContext === "project" &&
                  <>
                    <h2>Playbook Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                      <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || null}</div>
                      <hr />
                    </div>
                  </>
                }

                {currentSidePanelContext === "persona" &&
                  <>
                    <h2>Persona Focus</h2>
                    <div>
                      <img 
                        src={avatar.svgPath} 
                        alt={avatar.name} 
                        width="50"
                      />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || null}</div>
                      <hr />
                    </div>
                    <div>
                    <div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Date Created</div>
                        <CalendarTodayIcon /> {currentSidePanelObject.date_time_created || null}
                      </div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Date Due</div>
                        <CalendarTodayIcon /> {currentSidePanelObject.date_due || null}
                      </div>
                    </div>
                      <hr />
                    </div>
                  </>
                }

                </>
                
            )}
        
    </div>
  );
};

export default CompSideContextPanel;