import React, { useState, useEffect, useContext } from 'react';
import './CompSubjectivePanel.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PageviewIcon from '@mui/icons-material/Pageview';
import { PanelContext } from '../Contexts/PanelContext';
import CompUserAssignment from './CompUserAssignment.jsx';
import CompQuickEditPopover from './CompQuickEditPopover.jsx';
import CompQuickCreateSubjective from './CompQuickCreateSubjective.jsx';
import { getSubjectivesByObjectiveId } from '../fxAPI/fxSubjectivesAPI.js';

const CompSubjectivePanel = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [subjectiveCreateIsExpanded, setSubjectiveCreateIsExpanded] = useState(false);
  const [subjectivesByObjective, setSubjectivesByObjectiveData] = useState([]);
  const [error, setError] = useState(null);
  const [panelToUpdate, setPanelToUpdate] = useState('subjectivePanel');

  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  //const elementId = 'subjectivePanel';

  // Fetch subjective data
  const fxAPIFetchSubjectivesByObjectiveId = async (objectiveId) => {
    await getSubjectivesByObjectiveId(objectiveId, setError, setSubjectivesByObjectiveData);
    console.log('Fetched subjectives by objective ID');
  };

  // Handle refresh for the specific element
  const handleRefresh = async () => {
    console.log('Refreshing subjective panel data');
    await fxAPIFetchSubjectivesByObjectiveId(props.relatedObjective);
    resetElementRefresh(panelToUpdate); // Reset the refresh flag after completion
  };

  // Effect to fetch data on component mount and when refresh flag is triggered
  useEffect(() => {
    // Fetch subjectives on initial load
    fxAPIFetchSubjectivesByObjectiveId(props.relatedObjective);
  }, [props.relatedObjective]);

  // Effect to handle refresh logic when the flag changes
  useEffect(() => {
    if (elements[panelToUpdate]?.refresh) {
      handleRefresh(); // Trigger the refresh only when needed
      triggerElementRefresh(panelToUpdate); // Re-set refresh flag to prevent re-triggering
    }
  }, [elements[panelToUpdate]?.refresh, triggerElementRefresh, resetElementRefresh]);

  const handleToggleItem = (toggleValue) => {
    setIsExpanded(toggleValue);
    console.log('Panel toggle:', toggleValue);
  };

  const handleToggleSubjectiveCreate = (toggleValue) => {
    setSubjectiveCreateIsExpanded(toggleValue);
    console.log('Toggle subjective create:', toggleValue);
  };

  const handleSwitchPanelContext = (object_id, type, object) => {
    //updatePanelContext("objective", objective_id, object);
    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    setPanelToUpdate('sideContextPanel');
    triggerElementRefresh(panelToUpdate);
  };



  return (
    <div>
      <div className="fx-comp-subjective-panel-outer">
        <div className="fx-comp-subjective-panel-header">
          <div>
            {!isExpanded ? (
              <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(true)}>
                <ExpandMoreIcon />
              </button>
            ) : (
              <button className="fx-comp-nesting-expand-icon" onClick={() => handleToggleItem(false)}>
                <ExpandLessIcon />
              </button>
            )}
          </div>
          <div className="fx-comp-subjective-panel-header-content-items">
            <div className="fx-comp-subjective-panel-header-content-item">{props.panelName}</div>
          </div>
        </div>
        {isExpanded && (
          <div className="fx-comp-subjective-panel-content">
            {subjectivesByObjective.map((subjective) => (
              <div key={subjective.subjective_id} className="fx-comp-subjective-panel-item-outer">
                <div className="fx-comp-subjective-panel-view-name">{subjective.name}</div>
                <div className="fx-comp-subjective-panel-item-right-actions">
                  <div className="fx-comp-subjective-panel-view-icon">
                    <CompUserAssignment
                      userAssigned={subjective.user_assigned}
                      componentType="subjective"
                      componentData={subjective}
                    />
                  </div>
                  <div>
                    <CompQuickEditPopover
                      relatedObjectType="subjective"
                      relatedObjectId={subjective.subjective_id}
                      componentData={subjective}
                      relatedContext="subjectivePanel"
                    />
                  </div>
                  <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchPanelContext(subjective.subjective_id, "subjective", subjective)}>
                    <PageviewIcon />
                  </div>
                </div>
              </div>
            ))}

            <div className="fx-comp-subjective-panel-add-icon" onClick={() => handleToggleSubjectiveCreate(true)}>
              <AddCircleOutlineIcon /> Add Subjective
            </div>
            {subjectiveCreateIsExpanded && (
              <CompQuickCreateSubjective
                relatedObjective={props.relatedObjective}
                relatedProject={props.relatedProject}
                refreshParent={props.refreshParent}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompSubjectivePanel;