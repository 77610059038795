import React, { useState, useContext } from 'react';
import './CompPageTempMaster3Panel.css';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import CompSideNavMaster from './CompSideNavMaster';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CompActivityPanel from './CompActivityPanel';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CompHeader from './CompHeader';
import CompCRUDFormObjective from './CompCRUDFormObjective';
import CompCRUDFormSubjective from './CompCRUDFormSubjective';
import CompCRUDFormUserPersona from './CompCRUDFormUserPersona';
import CompCRUDFormProject from './CompCRUDFormProject';
import { PanelContext } from '../Contexts/PanelContext';
import { PanelProvider } from '../Contexts/PanelContext';
import CompSpeedDial from './CompSpeedDial';
import CompGlobalTaskBar from './CompGlobalTaskBar';
import CompSideContextPanel from './CompSideContextPanel';

const CompPageTempMaster3Panel = ({ children, headingOne }) => {
  // useContext inside the component
  const { panelContext, panelObjectId, panelObject } = useContext(PanelContext);

  const [isCol1Collapsed, setIsCol1Collapsed] = useState(false);
  const [isCol3Collapsed, setIsCol3Collapsed] = useState(false);

  //localStorage.setItem("fxSidePanelContext", "project");
  //localStorage.setItem("fxSidePanelObject", "test object");

  const currentSidePanelContext = localStorage.getItem("fxSidePanelContext");
  const currentSidePanelObject = localStorage.getItem("fxSidePanelObject");
  

  return (
    <div>  
      <CompHeader />
      <CompGlobalTaskBar />
      <Grid container>
        {/* Column 1 */}
        <Grid item xs={isCol1Collapsed ? 1 : 2}>
          <div className={`fx-comp-temp-3panel-col1-outer ${isCol1Collapsed ? 'collapsed' : ''}`}>
            <IconButton className="fx-comp-temp-3panel-col1-expand-button" onClick={() => setIsCol1Collapsed(!isCol1Collapsed)}>
              {isCol1Collapsed ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
            {!isCol1Collapsed && <CompSideNavMaster isCollapsed="not collapsed" />}
            {isCol1Collapsed && <CompSideNavMaster isCollapsed="collapsed" />}
          </div>
        </Grid>

        {/* Column 2 */}
        <Grid item xs={isCol1Collapsed && isCol3Collapsed ? 10 : isCol1Collapsed || isCol3Collapsed ? 9 : 8}>
          <div className="fx-comp-temp-3panel-col2-outer">
            <h1>{headingOne}</h1>
            {children}
          </div>
        </Grid>

        {/* Column 3 */}
        <Grid item xs={isCol3Collapsed ? 1 : 2}>
          <div className="uxc-debug">{panelContext} | {panelObjectId}</div>
          <div className={`fx-comp-temp-3panel-col3-outer ${isCol3Collapsed ? 'collapsed' : ''}`}>
            <IconButton className="fx-comp-temp-3panel-col3-expand-button" onClick={() => setIsCol3Collapsed(!isCol3Collapsed)}>
              {isCol3Collapsed ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
            {!isCol3Collapsed && 
              <CompSideContextPanel />
            }
            {!isCol3Collapsed && panelContext === "project" &&
              <>
                <h2>Playbook</h2>
                <CompCRUDFormProject actionModalTitle="Project" itemType="project" crudMethod="view-edit" currentComponentData={panelObject} />
              </>
            }
            {!isCol3Collapsed && panelContext === "persona" &&
              <>
                <h2>Persona</h2>
                <CompCRUDFormUserPersona actionModalTitle="Persona" itemType="persona" crudMethod="view-edit" currentComponentData={panelObject} />
              </>
            }
            {!isCol3Collapsed && panelContext === "objective" &&
              <>
                <h2>Objective</h2>
                <CompCRUDFormObjective actionModalTitle="Objective" itemType="objective" crudMethod="view-edit" currentComponentData={panelObject} />
              </>
            }
            {!isCol3Collapsed && panelContext === "subjective" &&
              <>
                <h2>Subjective</h2>
                <CompCRUDFormSubjective actionModalTitle="Subjective" itemType="subjective" crudMethod="view-edit" currentComponentData={panelObject} />
              </>
            }

            {!isCol3Collapsed && 
            !localStorage.getItem("fxSidePanelContext") && (
                <>
                  <CompActivityPanel />
                </>
            )}
          </div>
          
        </Grid>
      </Grid>
      {/*<CompSpeedDial />*/}
    </div>
  );
};

// Wrap the component in the PanelProvider in a separate place, like the top-level app component or wherever it's used

const WrappedCompPageTempMaster3Panel = (props) => (
  <PanelProvider>
    <CompPageTempMaster3Panel {...props} />
  </PanelProvider>
);

export default WrappedCompPageTempMaster3Panel;