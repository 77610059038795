import React, { useState } from "react";
import axios from "axios";
import './CompHubspotWaitlistForm.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const CompHubspotWaitlistForm = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Proxy server endpoint
    const url = "/api/hubspot/form";
  
    // HubSpot Forms API payload
    const payload = {
      fields: [
        { name: "firstname", value: formData.firstname },
        { name: "lastname", value: formData.lastname },
        { name: "email", value: formData.email },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
  
    try {
      await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("There was an issue submitting the form.");
    }
  };

  return (
    <div className="fx-comp-hubspot-waitlist-form-wrap">
        <form onSubmit={handleSubmit}>
            <div><TextField id="first-name" label="First Name" variant="outlined" value={formData.firstname} onChange={handleChange} fullWidth /></div>
            <br />
            <div><TextField id="last-name" label="Last Name" variant="outlined" value={formData.lastname} onChange={handleChange} fullWidth /></div>
            <br />
            <div><TextField id="email" label="Email" variant="outlined" value={formData.email} onChange={handleChange} fullWidth /></div>
            <br />
        <Button type="submit" variant="contained">Submit</Button>
        </form>
    </div>
  );
};

export default CompHubspotWaitlistForm;