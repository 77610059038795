import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './CompFlowWithSwimlanes.css';
import CompCRUDModal from './CompCRUDModal';
import { getPathsByProjectId } from '../fxAPI/fxPathsAPI';
import { getAnchorsPersonasPathsByPathId, getAnchorsPathsByPathId, getAnchorsPersonasByAnchorId } from '../fxAPI/fxAnchorsAPI';
import CompSwimlaneCard from './CompSwimlaneCard';
import CompUserPersonaAvatar from './CompUserPersonaAvatar';


const CompFlowWithSwimlanes = (props) => {

  const [error, setError] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(null);

  const [uniqueUserPersonas, setUniqueUserPersonas] = useState([]);

  

  /* ANCHORS PERSONAS BY ANCHOR ID */
  const [anchorsPersonasByAnchorId, setAnchorsPersonasByAnchorData] = useState([]); // Initialize as an array
  const [personasData, setPersonasData] = useState([]);
  /* get user personas by orgId */
  const fxAPIFetchAnchorsPersonasByAnchorId = async (anchorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        await getAnchorsPersonasByAnchorId(anchorId, setError, (data) => {
          console.log(`Data fetched for anchorId: ${anchorId}`, data);

          // Append the fetched data to the state
          setPersonasData((prevData) => [...prevData, data]);
          resolve(data);
        });
      } catch (error) {
        console.error(`Error fetching personas for anchorId: ${anchorId}`, error);
        setError(error); // Set error if any
        reject(error);
      }
    });
  };

  /* PATHS BY PROJECT ID */
  const [pathsByProjectId, setPathsByProjectData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchPathsByProjectId = async (projectId) => {
    await getPathsByProjectId(projectId, setError, setPathsByProjectData);
    console.log('all paths by project Id');
    console.dir(pathsByProjectId);
  };

  /* ANCHORS PERSONAS PATHS BY PATH ID */
  const [anchorsPersonasPathsByPathId, setAnchorsPersonasPathsByPathData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchAnchorsPersonasPathsByPathId = async (pathId) => {
    await getAnchorsPersonasPathsByPathId(pathId, setError, setAnchorsPersonasPathsByPathData);
    console.log('all anchors personas paths by path Id');
    console.dir(anchorsPersonasPathsByPathId);
  };

    /* ANCHORS PATHS BY PATH ID */
    const [anchorsPathsByPathId, setAnchorsPathsByPathData] = useState([]); // Initialize as an array
    /* get anchors paths by pathId */
    const fxAPIFetchAnchorsPathsByPathId = async (pathId) => {
      await getAnchorsPersonasPathsByPathId(pathId, setError, setAnchorsPathsByPathData);
      console.log('all anchors paths by path Id');
      console.dir(anchorsPathsByPathId);
    };

  

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePathChange = async (path) => {
    setCurrentPath(path);
    handleClose();
    await fxAPIFetchAnchorsPathsByPathId(path); // Make sure this fetches data before proceeding
  
    console.log('Fetched anchors for path on change:');
    console.dir(anchorsPathsByPathId);
  
    await compileUserPersonas(); // Ensure this runs to completion before moving forward
    console.log('User personas compiled');
  };

  useEffect(() => {
    if (anchorsPathsByPathId.length > 0) {
      compileUserPersonas();
    }
  }, [anchorsPathsByPathId]);

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchPathsByProjectId(props.relatedProject);
    if(currentPath) {
      fxAPIFetchAnchorsPersonasPathsByPathId(currentPath);
    }
    console.log('paths, user personas, and anchors in useEffect:');
    console.dir(anchorsPersonasPathsByPathId);
  }, []);

  const buildNestedPaths = (paths) => {
    const pathMap = {}; // Map to hold each path by its id
    const rootPaths = []; // Array to hold the top-level paths
  
    // Populate the map with each path
    paths.forEach((path) => {
      pathMap[path.path_id] = { ...path, children: [] };
    });
  
    // Iterate over the paths again to build the parent-child relationships
    paths.forEach((path) => {
      if (path.parent_path) {
        // If the path has a parent, add it to the parent's children array
        pathMap[path.parent_path].children.push(pathMap[path.path_id]);
      } else {
        // If no parent, it is a root path
        rootPaths.push(pathMap[path.path_id]);
      }
    });
  
    return rootPaths;
  };
  
  // Recursive function to render MenuItems, including nested ones
  const renderMenuItems = (paths, handlePathChange, level = 0) => {
    return paths.map((path) => (
      <React.Fragment key={path.path_id}>
        <MenuItem 
          onClick={() => handlePathChange(path.path_id)} 
          style={{ paddingLeft: `${level * 16}px` }} // Indent based on level
        >
          {path.name}
        </MenuItem>
        {path.children.length > 0 && renderMenuItems(path.children, handlePathChange, level + 1)}
      </React.Fragment>
    ));
  };

  const nestedPaths = buildNestedPaths(pathsByProjectId);

  const compileUserPersonas = async () => {
    console.log('Started compilation of unique user personas');
  
    console.log('Step 1: All Anchors');
    console.dir(anchorsPathsByPathId);
  
    // Wait for all data fetches to complete
    const fetchPromises = Object.values(anchorsPathsByPathId).map((item) =>
      fxAPIFetchAnchorsPersonasByAnchorId(item.anchor_id)
    );
  
    await Promise.all(fetchPromises); // Ensure all fetches are complete before proceeding
  
    console.log('Fetched all persona data:', personasData);
  
    // Flatten the array and extract unique pairs
    const uniqueCompiledUserPersonas = Array.from(
      personasData.flat().reduce((map, item) => {
        if (item.user_persona_id && item.user_persona_view_name) {
          const key = `${item.user_persona_id}:${item.user_persona_view_name}`;
          map.set(key, {
            user_persona_id: item.user_persona_id,
            user_persona_view_name: item.user_persona_view_name,
            related_avatar: item.related_avatar || null, // Extract the related_avatar property
          });
        }
        return map;
      }, new Map())
    ).map(([, value]) => value); // Extract the value (object) from the Map
  
    setUniqueUserPersonas(uniqueCompiledUserPersonas); // Update the state
    console.log('Final compilation of unique personas:', uniqueCompiledUserPersonas);
  };
  
  // Call the function to fetch data
  
  

  return (
    <div>
    <div className="fx-comp-flow-with-swimlanes-toolbar">
      <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle="Create Path" itemType="path" crudMethod="create" modalComponentData="" relatedProject={props.relatedProject}></CompCRUDModal>
      <div>Selected Path: {currentPath || ""}</div>
      <Button onClick={() => openModal("path", "create", "")}>Create Path</Button>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Select Path
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {renderMenuItems(nestedPaths, handlePathChange)}
      </Menu>
    
    </div>

    <div>
      List of Anchors for Path Test:
    {anchorsPathsByPathId.map((anchor) => (
      <div key={anchor.anchor_id}>
        {anchor.anchor_view_name}
      </div>
                ))}
    </div>

    <div>
        <Button onClick={compileUserPersonas}>Test User Persona matching</Button>
    </div>

    <hr />

    <div className="fx-comp-swimlane-flow-main-path-container">
        <div className="fx-comp-swimlane-flow-main-path-col">
            <div className="fx-comp-swimlane-flow-main-path-col-heading">
                User Personas
            </div>
            
            {Object.values(uniqueUserPersonas).map((persona) => (
            <div key={persona.user_persona_id} className="fx-comp-swimlane-flow-main-path-cell">
              <div>{persona.user_persona_view_name}</div>
              {/*<p>ID: {persona.related_avatar}</p>*/}
              <div className="fx-comp-swimlane-flow-avatar-outer">
                <CompUserPersonaAvatar avatarId={persona.related_avatar} />
              </div>
            </div>
            ))}

        </div>
        {anchorsPathsByPathId.map((anchor) => (
        <div className="fx-comp-swimlane-flow-main-path-col" key={anchor.anchor_id}>
          <div className="fx-comp-swimlane-flow-main-path-col-heading">
                <div>{anchor.anchor_view_name}</div>
          </div>
          {Object.values(uniqueUserPersonas).map((persona) => (
                <div className="fx-comp-swimlane-flow-main-path-cell">
                  <CompSwimlaneCard anchorType={anchor.type} personaName={persona.user_persona_view_name} anchorId={anchor.anchor_id} userPersonaId={persona.user_persona_id} />
                </div>
          ))}
        </div>
        ))}
    </div> {/* End Flow Container */}

    </div>
  );
}

export default CompFlowWithSwimlanes;