import React, { useEffect, useState } from 'react';
import './CompCRUDFormPath.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createPath, updatePath } from '../fxAPI/fxPathsAPI.js';

const CompCRUDFormPath = (props) => {

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pathResponse, setPathResponse] = useState(null);
  const [updatePathName, setUpdatePathName] = useState(props.currentComponentData.name);
  const [updatePathDescription, setUpdatePathDescription] = useState(props.currentComponentData.description);

  console.log('current path data on form path: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCreatePath = async (event) => {
    event.preventDefault();
    const related_org = localStorage.getItem("fxLocalOrgID");
    const parent_path = null;
    const primary_persona = null;
    const favorite = "no";
    const related_project = props.relatedProject;

    await createPath(name, description, parent_path, primary_persona, favorite, related_org, related_project, setPathResponse);
  };

  const handleUpdatePathNameChange = (e) => {
    setUpdatePathName(e.target.value);
  }

  const handleUpdatePathDescriptionChange = (e) => {
    setUpdatePathDescription(e.target.value);
  }

  const handleUpdatePath = async (e) => {
    console.log('ran update path: ' + props.currentComponentData.path_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const pathId = props.currentComponentData.path_id;
    const updateName = updatePathName;
    const updateDescription = updatePathDescription;
    const related_org = relatedOrg;
    const parent_path = null;
    const primary_persona = null;
    const favorite = "no";
    const related_project = props.relatedProject;

  
    await updatePath(pathId, updateName, updateDescription, parent_path, primary_persona, favorite, related_org, related_project);
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreatePath}>
              <div>
                <TextField id="outlined-basic" label="Path Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Path Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Create Path</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Path Name</div>
                <div>{props.currentComponentData.name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Path Description</div>
                <div>{props.currentComponentData.description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdatePath}>
                <div>
                  <TextField id="outlined-basic" label="Path Name" variant="outlined" value={updatePathName} onChange={handleUpdatePathNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Path Description"
                  value={updatePathDescription}
                  onChange={handleUpdatePathDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save Path</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormPath;