

export const fetchCaseTypes = async () => {
    try {
      const response = await fetch('/fxapi/int/pega/case-types', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching case types:', error);
      throw error;
    }
};

export const fetchCaseTypeDetail = async (id) => {
    if (!id) {
      throw new Error('ID parameter is required');
    }
  
    try {
      const response = await fetch(`/fxapi/int/pega/case-type-detail?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching case type detail:', error);
      throw error;
    }
};

export const fetchFlowActionsByClass = async (dataPage, className) => {


  if (!dataPage || !className) {
    throw new Error('ID and class name parameters are required');
  }

  //const dataPageTest = "D_FXFlowActionsByClassList";
  //const classNameTest = "OIWMAC-FluxityInt-Work-FluxitySample";


  try {
    const response = await fetch(`/fxapi/int/pega/flow-actions-by-class?dataPage=${dataPage}&className=${className}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching case type detail:', error);
    throw error;
  }
};