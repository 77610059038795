import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { updateUserPersona } from '../fxAPI/fxUserPersonasAPI';
import './CompAvatarChooserModal.css';
import avatars from '../Data/avatars.json';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  boxShadow: 24,
  p: 4,
};


const CompAvatarChooserModal = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const passedAvatar = props.avatarId || '1';

  useEffect(() => {
    const avatar = avatars.avatars.find(a => a.id === passedAvatar);
    setSelectedAvatar(avatar);
  }, [passedAvatar]);

  if (!selectedAvatar) return <p>Loading avatar...</p>;

  const handleAvatarSelect = async (avatarId) =>  {
    const avatar = avatars.avatars.find(a => a.id === avatarId);
    setSelectedAvatar(avatar);
    console.log('selected avatar: ');
    console.dir(selectedAvatar);
    await updateUserPersona(props.userPersonaId, null, null, avatarId, null);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Choose Avatar</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="fx-comp-avatar-chooser-modal-selected-avatar-wrapper">
            <strong>Current Avatar: {selectedAvatar.name}</strong>
            <img src={selectedAvatar.svgPath} alt={selectedAvatar.name} width="100" />
          </div>
          <hr />
          <div className="fx-comp-avatar-chooser-modal-gallery">
            <strong>Avatar Gallery</strong>
            <div className="fx-comp-avatar-chooser-modal-gallery-grid">
              {avatars.avatars.map((avatar) => (
                <div 
                  key={avatar.id} 
                  className="fx-comp-avatar-chooser-modal-gallery-item" 
                  onClick={() => handleAvatarSelect(avatar.id)}
                  style={{ cursor: 'pointer', margin: '10px' }}
                >
                  <img 
                    src={avatar.svgPath} 
                    alt={avatar.name} 
                    width="50" 
                    style={{ border: selectedAvatar.id === avatar.id ? '2px solid #007bff' : 'none', padding: '2px' }} 
                  />
                  <p>{avatar.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="fx-comp-avatar-chooser-modal-button-row">
            <Button onClick={handleClose}>Close</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CompAvatarChooserModal;