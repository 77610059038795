import axios from 'axios';

const createPath = async (name, description, parent_path, primary_persona, favorite, related_org, related_project, setPathResponse) => {
    try {
      // Generate tagId
      const pathId = generateUUID();
  
      // Simple validation
      if (!pathId || !name || !related_org) {
        console.warn('Missing required parameters: pathId, name, or related_org.');
        return;
      }
  
      console.log(`Creating path for orgId: ${related_org}, name: ${name}`);
  
      // Send request
      const response = await axios.post('/fxapi/paths/create', {
        path_id: pathId,
        name,
        description,
        parent_path,
        primary_persona,
        favorite,
        related_org,
        related_project
      });
  
      console.log('API response received:', response.data);
  
      // Update state with response data
      setPathResponse(response.data);
  
    } catch (error) {
      // Suppress the error, just log it quietly
      console.error('Error creating path:', error);
      // Optionally, handle specific errors here without alerting the user
    }
  };

  const getPathsByOrgId = async (orgId, setError, setPathsByOrgData) => {
    setError(null);
    setPathsByOrgData([]); // Reset Paths data to an empty array

    if (!orgId) {
      setError('orgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching path data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/paths/paths-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting path data:', items);
      setPathsByOrgData(items); // Correctly update the state with received data
      console.log('Updated path data:', items);
    } catch (error) {
      console.error('Error fetching path data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch paths data');
    }
};

const getPathsByProjectId = async (projectId, setError, setPathsByProjectData) => {
  setError(null);
  setPathsByProjectData([]); // Reset Paths data to an empty array

  if (!projectId) {
    setError('projectId parameter is required');
    return;
  }

  try {
    console.log(`Fetching path data for projectId: ${projectId}`);
    const response = await axios.get('/fxapi/paths/paths-by-project', {
      params: { projectId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting path data:', items);
    setPathsByProjectData(items); // Correctly update the state with received data
    console.log('Updated path data:', items);
  } catch (error) {
    console.error('Error fetching path data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch paths data');
  }
};

const updatePath  = async (pathId, name, description, parent_path, primary_persona, favorite, related_org, related_project) => {

    try {
      const response = await axios.put(`/fxapi/paths/update/${pathId}`, {
        path_id: pathId,
        name,
        description,
        parent_path,
        primary_persona,
        favorite,
        related_org,
        related_project
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

const deletePath = async (pathId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!pathId) {
      setError('pathId parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting path with ID: ${pathId}`);
      const response = await axios.delete(`/fxapi/paths/delete/${pathId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted path with ID: ${pathId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting path:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete path';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  // Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  
  export { createPath, getPathsByOrgId, deletePath, updatePath, getPathsByProjectId};