import React, { useState, useEffect, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModal from './CompCRUDModal';
import { PanelContext } from '../Contexts/PanelContext';



const CompQuickEditPopover = (props) => {
  
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  //const [modalComponentData, setModalComponentData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  /* use global context */
  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = props.relatedContext;

  // Handle refresh for the specific element
  const handleRefresh = async () => {
    console.log('Refreshing subjective panel data');
    resetElementRefresh(elementId); // Reset the refresh flag after completion
  };

  const openModal = (currentCRUDMethod) => {
    handleClose();
    setIsOpen(true);
    setCRUDMethod(currentCRUDMethod);
    if(props.relatedObjectType === "subjective" && currentCRUDMethod === "view-edit") {
      setActionModalTitle('Edit Subjective');
    } else if(props.relatedObjectType === "subjective" && currentCRUDMethod === "delete") {
      setActionModalTitle('Delete Subjective');
    }

  };

  const closeModal = () => {
    setIsOpen(false);
    //handleRefresh(); // Trigger the refresh only when needed
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log('clicked popover for: ' + props.relatedObjectType + ': ' + props.relatedObjectId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <CompCRUDModal isOpen={isOpen} onClose={closeModal} closeModal={closeModal} relatedComponentId={props.relatedObjectId} modalTitle={actionModalTitle} itemType={props.relatedObjectType} crudMethod={crudMethod} modalComponentData={props.componentData}></CompCRUDModal>
        <div onClick={handleClick}><EditIcon /></div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '.MuiPaper-root': {
            padding: '16px',  // Control padding here
          },
          '.fx-comp-quick-edit-popover-item': {
            cursor: 'pointer',
          }
        }}
      >
        <div className="fx-comp-quick-edit-popover-item" onClick={() => openModal("view-edit")}>Edit</div>
        <div className="fx-comp-quick-edit-popover-item" onClick={() => openModal("delete")}>Delete</div>
      </Popover>
    </div>
  );
};

export default CompQuickEditPopover;