import React from 'react';
import './CompTimelineMinimal.css';


const CompTimelineMinimal = (props) => {

  return (
    <div className="fx-comp-timeline-minimal-container">
      <div className="fx-comp-timeline-minimal-milestone">
        <div class="dot"></div>
        ... Milestone ## / ## ...
      </div>
      <div className="fx-comp-timeline-minimal-milestone">
        <div class="dot"></div>
        ... Milestone ## / ## ...
      </div>
    </div>
  );
};

export default CompTimelineMinimal;
