import axios from 'axios';

const createObjectLink = async (initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org) => {
    try {
      // Generate linkId
      const linkId = generateUUID();
  
      // Simple validation
      if (!linkId || !related_org) {
        console.warn('Missing required parameters: linkId, or related_org.');
        return;
      }
  
      console.log(`Creating object link for orgId: ${related_org}`);
  
      // Send request
      const response = await axios.post('/fxapi/object-links/create', {
        link_id: linkId,
        initial_object_id,
        initial_object_type,
        linking_object_id,
        linking_object_type,
        relationship_type,
        related_project, 
        related_org
      });
  
      console.log('API response received:', response.data);
  
      // Update state with response data
      console.log(response.data);
  
    } catch (error) {
      // Suppress the error, just log it quietly
      const linkId = generateUUID();
      console.error('Error creating object link:', error);
      console.log('data attempted: ');
      console.log('link_id: ' + linkId);
      console.log('initial_object_id: ' + initial_object_id);
      console.log('intial_object_type: ' + initial_object_type);
      console.log('linking_object_id: ' + linking_object_id);
      console.log('linking_object_type: ' + linking_object_type);
      console.log('relationship_type: ' + relationship_type);
      console.log('related_project: ' + related_project);
      console.log('related_org: ' + related_org);
      // Optionally, handle specific errors here without alerting the user
    }
  };

  const getObjectLinksByOrgId = async (orgId, setError, setObjectLinksByOrgData) => {
    setError(null);
    setObjectLinksByOrgData([]); // Reset Anchors data to an empty array

    if (!orgId) {
      setError('orgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching object link data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/object-links/object-links-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting object link data:', items);
      setObjectLinksByOrgData(items); // Correctly update the state with received data
      console.log('Updated object link data:', items);
    } catch (error) {
      console.error('Error fetching object link data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch object links data');
    }
};

  const getObjectLinksByProjectId = async (projectId, setError, setObjectLinksByProjectData) => {
    setError(null);
    setObjectLinksByProjectData([]); // Reset object links data to an empty array

    if (!projectId) {
      setError('projectId parameter is required');
      return;
    }

    try {
      console.log(`Fetching object link data for projectId: ${projectId}`);
      const response = await axios.get('/fxapi/object-links/object-links-by-project', {
        params: { projectId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting object link data:', items);
      setObjectLinksByProjectData(items); // Correctly update the state with received data
      console.log('Updated object link data:', items);
    } catch (error) {
      console.error('Error fetching anchor data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch object links data');
    }
};

const getObjectLinksByInitialObjectAndLinkTypeAndOrg = async (initialObject, orgId, linkType, setError, setObjectLinksByInitialObjectAndLinkTypeAndOrgData) => {
  setError(null);
  setObjectLinksByInitialObjectAndLinkTypeAndOrgData([]); // Reset Anchors data to an empty array

  if (!initialObject || !orgId || !linkType) {
    setError('initialObject, orgId, and linkType parameters are required');
    return;
  }

  try {
    console.log(`Fetching object link data for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/object-links/object-links-by-initial-object-and-object-type-and-org', {
      params: { initialObject, orgId, linkType }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting object link data:', items);
    setObjectLinksByInitialObjectAndLinkTypeAndOrgData(items); // Correctly update the state with received data
    console.log('Updated object link data:', items);
  } catch (error) {
    console.error('Error fetching object link data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch object links data');
  }
};

const deleteObjectLink = async (linkId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!linkId) {
      setError('linkId parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting object link with ID: ${linkId}`);
      const response = await axios.delete(`/fxapi/object-links/delete/${linkId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted object link with ID: ${linkId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting link:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete link';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  const updateObjectLink = async (linkId, initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org) => {

    try {
      const response = await axios.put(`/fxapi/object-links/update/${linkId}`, {
        link_id: linkId,
        initial_object_id,
        initial_object_type,
        linking_object_id,
        linking_object_type,
        relationship_type,
        related_project, 
        related_org
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  // Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  
  export { createObjectLink, getObjectLinksByOrgId, getObjectLinksByProjectId, getObjectLinksByInitialObjectAndLinkTypeAndOrg, deleteObjectLink, updateObjectLink};