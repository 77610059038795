import axios from 'axios';

const getUserPersonasByOrgId = async (orgId, setError, setUserPersonasData) => {
    setError(null);
    setUserPersonasData([]); // Reset user personas data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching user persona data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/user-personas/user-personas-by-org', {
        params: { orgId }
      });
      console.log('API response received1:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting user personas data 1:', items);
      setUserPersonasData(items); // Correctly update the state with received data
      console.log('Updated user persona data 1:', items);
    } catch (error) {
      console.error('Error fetching user persona data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch user personas 1 data');
    }
};

const createUserPersona = async (name, description, related_avatar, related_org, setUserPersonaResponse) => {
  try {
    // Generate userPersonaId
    const userPersonaId = generateUUID();

    console.log('Attempting User Persona Create with Data: ');
    console.log('name: ' + name);
    console.log('description: ' + description);
    console.log('related_avatar: ' + related_avatar);
    console.log('related_org: ' + related_org); 

    // Simple validation
    if (!userPersonaId || !name || !description || !related_org) {
      console.warn('Missing required parameters: userPersonaId, name, description, or related_org.');
      return;
    }

    console.log(`Creating user persona for orgId: ${related_org}, User Persona Name: ${name}`);

    // Send request
    const response = await axios.post('/fxapi/user-personas/create', {
      user_persona_id: userPersonaId,
      name,
      description,
      related_avatar,
      related_org
    });

    console.log('API response received:', response.data);

    // Update state with response data
    setUserPersonaResponse(response.data);

  } catch (error) {
    // Suppress the error, just log it quietly
    console.error('Error creating user persona:', error);
    // Optionally, handle specific errors here without alerting the user
  }
};

const updateUserPersona  = async (userPersonaId, name, description, related_avatar, related_org) => {

  try {
    const response = await axios.put(`/fxapi/user-personas/update/${userPersonaId}`, {
      user_persona_id: userPersonaId,
      name: name,
      description: description,
      related_avatar: related_avatar,
      related_org: related_org
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

const deleteUserPersona = async (userPersonaId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!userPersonaId) {
    setError('userPersonaId parameter is required');
    return;
  }

  try {
    console.log(`Deleting user Persona with ID: ${userPersonaId}`);
    const response = await axios.delete(`/fxapi/user-persona/delete/${userPersonaId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted user persona with ID: ${userPersonaId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting user persona:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete user persona';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

// Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export { getUserPersonasByOrgId, createUserPersona, deleteUserPersona, updateUserPersona };