import React, { useState, useEffect } from 'react';
import './CompSwimlaneCard.css';
import { getAnchorsPersonasByAnchorId } from '../fxAPI/fxAnchorsAPI';


const CompSwimlaneCard = (props) => {
  const [error, setError] = useState(null);
  const [anchorsPersonasByAnchorId, setAnchorsPersonasByAnchorData] = useState([]);
  const [isStepCard, setIsStepCard] = useState(false); // New state for isStepCard

  /* get user personas by orgId */
  const fxAPIFetchAnchorsPersonasByAnchorId = async (anchorId) => {
    await getAnchorsPersonasByAnchorId(anchorId, setError, setAnchorsPersonasByAnchorData);
    console.log('Data fetch initiated for anchorId:', anchorId);
  };

  // Check if anchor_id and user_persona_id match and update isStepCard
  useEffect(() => {
    const checkIfStepCard = () => {
      const match = anchorsPersonasByAnchorId.some(
        (persona) =>
          persona.anchor_id === props.anchorId &&
          persona.user_persona_id === props.userPersonaId
      );
      setIsStepCard(match); // Set isStepCard to true if match found, otherwise false
    };

    checkIfStepCard();
  }, [anchorsPersonasByAnchorId, props.anchorId, props.userPersonaId]); // Run the effect when state or props change

  useEffect(() => {
    fxAPIFetchAnchorsPersonasByAnchorId(props.anchorId);
  }, [props.anchorId]);

  function ResponsiveSVG() {
    return (
      <div style={{ width: '100%', maxWidth: '400px' }}>
        <svg
          viewBox="0 0 200 100"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: '100%', height: 'auto' }}
        >
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            rx="15"
            ry="15"
            fill="#ecf3ec"
            stroke="#00a91c"
            strokeWidth="4" // Use camelCase for attributes like 'stroke-width'
          />
        </svg>
      </div>
    );
  }

  return (
    <div>
      <div className="fx-comp-swimlane-card-demo-text">{props.personaName}</div>
      {isStepCard ? (
        <>
        {props.anchorType === "assignment" &&
          <>
          <div>
            <ResponsiveSVG />
          </div>
          </>
        }
        </>
      ) : (
        <div className="fx-comp-swimlane-card-notify-text">This is NOT a Step Card</div>
      )}
    </div>
  );
};

export default CompSwimlaneCard;