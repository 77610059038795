import axios from 'axios';

const createAnchor = async (name, related_project, related_org, position, type, setAnchorResponse) => {
    try {
      // Generate projectId
      const anchorId = generateUUID();
  
      // Simple validation
      if (!anchorId || !name || !related_org) {
        console.warn('Missing required parameters: anchorId, name, related_project, or related_org.');
        return;
      }
  
      console.log(`Creating anchor for orgId: ${related_org}, projectName: ${name}`);
  
      // Send request
      const response = await axios.post('/fxapi/anchors/create', {
        anchor_id: anchorId,
        name,
        related_project,
        related_org,
        position,
        type
      });
  
      console.log('API response received:', response.data);
  
      // Update state with response data
      setAnchorResponse(response.data);
  
    } catch (error) {
      // Suppress the error, just log it quietly
      console.error('Error creating anchor:', error);
      // Optionally, handle specific errors here without alerting the user
    }
  };

  const getAnchorsByProjectId = async (projectId, setError, setAnchorsByProjectData) => {
    setError(null);
    setAnchorsByProjectData([]); // Reset data to an empty array

    if (!projectId) {
      setError('projectId parameter is required');
      return [];
    }

    try {
      console.log(`Fetching anchor data for projectId: ${projectId}`);
      const response = await axios.get('/fxapi/anchors/anchors-by-project', {
        params: { projectId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      setAnchorsByProjectData(items); // Update state with the received items
      return items; // Ensure the data is returned here
    } catch (error) {
      console.error('Error fetching anchor data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch anchors data');
      return []; // Return an empty array on error
    }
};

const getAnchorsObjectivesByProjectId = async (projectId, setError, setAnchorsObjectivesByProjectData) => {
  setError(null);
  setAnchorsObjectivesByProjectData([]); // Reset Anchors data to an empty array

  if (!projectId) {
    setError('projectId parameter is required');
    return;
  }

  try {
    console.log(`Fetching anchor objective data for projectId: ${projectId}`);
    const response = await axios.get('/fxapi/anchors/anchors-objectives-by-project', {
      params: { projectId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting anchor objective data:', items);
    setAnchorsObjectivesByProjectData(items); // Correctly update the state with received data
    console.log('Updated anchor objective data:', items);
  } catch (error) {
    console.error('Error fetching anchor objective data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch anchors data');
  }
};

const getAnchorsPersonasByAnchorId = async (anchorId, setError, setAnchorsPersonasByAnchorData) => {
  setError(null);
  setAnchorsPersonasByAnchorData([]); // Reset Anchors data to an empty array

  if (!anchorId) {
    setError('anchorId parameter is required');
    return;
  }

  try {
    console.log(`Fetching anchor persona data for anchorId: ${anchorId}`);
    const response = await axios.get('/fxapi/anchors/anchors-personas-by-anchor', {
      params: { anchorId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting anchor persona data:', items);
    setAnchorsPersonasByAnchorData(items); // Correctly update the state with received data
    console.log('Updated anchor persona data:', items);
  } catch (error) {
    console.error('Error fetching anchor persona data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch anchors data');
  }
};

const getAnchorsPathsByPathId = async (pathId, setError, setAnchorsPathsByPathData) => {
  setError(null);
  setAnchorsPathsByPathData([]); // Reset Anchors data to an empty array

  if (!pathId) {
    setError('pathId parameter is required');
    return;
  }

  try {
    console.log(`Fetching anchor path data for pathId: ${pathId}`);
    const response = await axios.get('/fxapi/anchors/anchors-paths-by-path', {
      params: { pathId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting anchor path data:', items);
    setAnchorsPathsByPathData(items); // Correctly update the state with received data
    console.log('Updated anchor path data:', items);
  } catch (error) {
    console.error('Error fetching anchor path data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch anchor path data');
  }
};

const getAnchorsPersonasPathsByPathId = async (pathId, setError, setAnchorsPersonasPathsByPathData) => {
  setError(null);
  setAnchorsPersonasPathsByPathData([]); // Reset Anchors data to an empty array

  if (!pathId) {
    setError('anchorId parameter is required');
    return;
  }

  try {
    console.log(`Fetching path persona data for pathId: ${pathId}`);
    const response = await axios.get('/fxapi/anchors/anchors-personas-paths-by-path', {
      params: { pathId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting anchor persona path data:', items);
    setAnchorsPersonasPathsByPathData(items); // Correctly update the state with received data
    console.log('Updated anchor persona path data:', items);
  } catch (error) {
    console.error('Error fetching anchor persona path data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch anchors persona path data');
  }
};

const deleteAnchor = async (anchorId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!anchorId) {
      setError('anchorId parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting anchor with ID: ${anchorId}`);
      const response = await axios.delete(`/fxapi/anchors/delete/${anchorId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted anchor with ID: ${anchorId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting anchor:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete anchor';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  const updateAnchor = async (anchorId, name, related_project, related_org, position, type) => {

    try {
      const response = await axios.put(`/fxapi/anchors/update/${anchorId}`, {
        anchor_id: anchorId,
        name: name,
        related_project: related_project,
        related_org: related_org,
        position: position,
        type: type
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  // Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  
  export { createAnchor, getAnchorsByProjectId, getAnchorsObjectivesByProjectId, getAnchorsPersonasByAnchorId, deleteAnchor, updateAnchor, getAnchorsPersonasPathsByPathId, getAnchorsPathsByPathId};