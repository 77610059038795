import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageTeam.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getUsersByOrgId } from '../fxAPI/fxUsersAPI.js';


const PageTeam = (props) => {


  const [error, setError] = useState(null);

  /* Users by Org */
  const [usersByOrgId, setUsersByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUsersByOrgId = async (orgId) => {
    await getUsersByOrgId(orgId, setError, setUsersByOrgData);
    console.log('all users by org Id');
    console.dir(usersByOrgId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchUsersByOrgId(currentOrgId);
    
  }, []);

  return (
    <CompPageTempMaster3Panel headingOne="My Team">

      <div>
        <h3>Team Members</h3>
        <div>
        {usersByOrgId.map((userItem) => (
          <>
          <div>{userItem.user_id}</div>
          <div>{userItem.first_name}</div>
          <div>{userItem.last_name}</div>
          <div>{userItem.username}</div>
          <div>{userItem.role}</div>
          </>
        ))}
        </div>
      </div>
      
      {/*
      <CompContentBlock headingThree="Integration 1: ...">
        Integrations...
        {integrationsByOrgId.map((integrationItem) => (
          <div>{integrationItem.integration_id}</div>
        ))}
      </CompContentBlock>
      */}

      
    </CompPageTempMaster3Panel>
  );
}

export default PageTeam;